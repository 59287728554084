import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { IStyledTitle } from "../interface/style-types"
import { deviceMax } from "../styles/mediaqueries"
import { colors } from "./../styles/colors"
import { fontweight } from "../styles/variables"
import IllustrationCity from "./Illustrations/IllustrationCity"

const StyledIntroWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100vh;
    justify-content: center;
`

const StyledTitle = styled.h1<IStyledTitle>`
    flex: 1;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${fontweight.semibold};
    color: ${colors.darkBlue} !important;
    letter-spacing: 5px;
    max-width: 50vw;
    font-size: 35px;
    padding: 94px 0rem 2rem 0rem;
    @media ${deviceMax.mobileHorizontal} {
        font-size: 22px;
    }
    @media ${deviceMax.desktop} {
        font-size: 30px;
    }
    @media ${deviceMax.desktopL} {
        font-size: 30px;
    }
    @media ${deviceMax.laptop} {
        font-size: 22px;
    }
    @media ${deviceMax.tablet} {
        font-size: 18px;
        max-width: 80vw;
    }
    @media ${deviceMax.mobileL} {
        font-size: 22px;
        max-width: 75vw;
    }
    @media ${deviceMax.mobileM} {
        font-size: 21px;
        max-width: 80vw;
    }

    @media ${deviceMax.mobileS} {
        font-size: 17px;
    }
`

const StyledIngress = styled.div`
    background: ${colors.darkBlue};
    text-align: center;
    width: 100vw;
    margin: 0;
    > .container {
        max-width: 1140px;
        @media ${deviceMax.laptopL} {
            max-width: 940px;
        }
        > p {
            padding: 4rem 0;
            line-height: 46px;
            color: ${colors.white};
            font-weight: ${fontweight.semibold};
            @media ${deviceMax.desktop} {
                font-size: 27px;
            }
            @media ${deviceMax.desktopL} {
                font-size: 25px;
            }
            @media ${deviceMax.laptop} {
                font-size: 20px;
            }
            @media ${deviceMax.tablet} {
                font-size: 19px;
                padding: 3rem;
            }
            @media ${deviceMax.mobileL}, ${deviceMax.mobileM} {
                font-size: 18px;
                padding: 2rem 3rem;
                line-height: 30px;
            }

            @media ${deviceMax.mobileS} {
                font-size: 14px;
            }
        }
    }
`

const StyledfrontpageIllustrationWrapper = styled.div`
    position: relative;
    top: 5px;
    @media ${deviceMax.laptop} {
        top: 2px;
    }
`

const Header: React.FC<{ data?: any }> = ({ data }) => {
    const frontmatter = data
    return (
        <header id="header">
            <StyledIntroWrapper className="hero is-medium">
                <StyledTitle>
                    <div
                        data-sal="slide-up"
                        data-sal-delay="150"
                        data-sal-easing="ease"
                    >
                        {frontmatter && frontmatter.slogan}
                    </div>
                </StyledTitle>
                <StyledfrontpageIllustrationWrapper>
                    <IllustrationCity width="100vw" height="100%" />
                </StyledfrontpageIllustrationWrapper>
            </StyledIntroWrapper>
            <StyledIngress>
                <div className="container">
                    <p>{frontmatter && frontmatter.ingress}</p>
                </div>
            </StyledIngress>
        </header>
    )
}

export default Header
