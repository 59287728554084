import { SvgProps } from "../../interface/svg"
import React from "react"

export const IllustrationDeveloper: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgProps.width ? SvgProps.width : '275px'}
            height={SvgProps.height ? SvgProps.height : '163px'}
            viewBox="0 0 275 163"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fillRule="nonzero" transform="translate(-291 -2368)">
                    <g transform="translate(150 1918)">
                        <g transform="translate(141 450)">
                            <path
                                fill="#ABC3F5"
                                d="M253.672 119.1c-.306.676-.61 1.344-.915 2.003a651.519 651.519 0 01-1.405 3.01c-.206.436-.412.864-.612 1.292a405.097 405.097 0 01-6.183 12.424c-3.324 6.386-9.775 10.403-16.826 10.478l-39.988.447h-.422l-3.428.038-2.932.032-8.992.1h-.364l-4.577.052h-.14l-28.215.314-25.184.27-2.93.033-18.194.203h-.021l-11.8.13h-.127l-37.311.414-11.162.125c-.95.01-1.9-.038-2.845-.146a22.852 22.852 0 01-.963-.133c-5.205-.825-9.979-3.457-13.524-7.456-4.802-5.423-8.296-11.75-10.642-18.617-.34-.99-.656-1.994-.95-3.01C-5.104 92.905 4.8 56.525 22.625 32.18a93.267 93.267 0 012.279-2.978l.018-.022c5.482-6.91 11.629-12.682 18.176-16.786 11.602-7.286 24.457-9.338 37.1-3.222 10.698 5.177 20.687 7.61 30.37 8.271.977.065 1.956.114 2.929.144 22.21.717 43.025-7.5 67.47-12.804.972-.21 1.948-.42 2.931-.617 17.438-3.563 36.782-5.442 59.786-1.492 2.39.401 4.748.98 7.056 1.733 1.014.336 1.992.697 2.932 1.083 10.98 4.586 16.968 13.06 19.513 23.68.24.985.45 1.987.625 3.007 4.512 25.542-8.702 61.773-20.138 86.922z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M129.519 121a43.648 43.648 0 01-.519 4h26v-4h-25.481zM110 138l3-.022v-6.848c-1.029-.043-2.03-.085-3-.13v7zm-61-17v4h53v-4H49zm223.377-92.036h-19.455V5.332c-.937-.391-1.912-.751-2.923-1.08v24.712h-66.656V4c-.981.197-1.954.405-2.924.616v24.348h-67.268V17.39c-.97-.03-1.946-.079-2.921-.143v11.717H42.96V12.217c-6.528 4.095-12.656 9.854-18.122 16.747h-.018v.022c-.771.972-1.528 1.963-2.272 2.971h2.272v88.718H3c.29 1.013.606 2.015.946 3.003h20.872v25.289a22.355 22.355 0 004.167.886c.942.108 1.89.157 2.837.145l11.129-.124v-26.201h67.279v25.448l2.92-.033v-25.41h67.27v24.678l2.923-.032v-24.646h66.643v1.289c.2-.427.404-.854.61-1.289.46-.981.928-1.982 1.4-3.003.304-.656.608-1.322.913-2V31.969H273a45.095 45.095 0 00-.623-3.004zM44 121V33h66v88H44zm70 0V33h66v88h-66zm136 0h-66V33h65.995l.005 88z"
                                opacity="0.263"
                            ></path>
                            <path
                                fill="#65617D"
                                d="M246.494891 156.517625L246.494891 160.531926 199.583152 160.964236 199.082909 160.973499 181.253236 161.137159 180.827103 161.143335 175.407797 161.189654 175.247225 161.189654 142.212616 161.495359 87.9855854 161.99869 87.9392665 161.99869 74.1516866 162.128383 74.0034663 162.131471 12.7390576 162.693473 12.7390576 158.061587 27.1442223 149.415401 56.0471893 149.174543 56.664774 149.171455 56.868577 149.168367 57.4861618 149.159103 71.3632914 149.04485 71.9839641 149.041762 72.1815912 149.038674 72.799176 149.035586 77.2488742 148.998531 77.3662153 148.995443 88.3437845 148.902805 88.4333343 148.902805 174.092341 148.180231 176.189041 148.164791 176.39902 148.164791 177.998565 148.149352 189.624598 148.053626 190.242183 148.044362 190.813449 148.038187 191.109889 148.038187 194.725848 148.010395 195.442246 148.001131 196.402591 147.994956 199.413316 147.967164 204.190335 147.927021 204.807919 147.920845 205.882517 147.91467 206.083232 147.91467 229.153111 147.717042z"
                            ></path>
                            <path
                                fill="#253260"
                                d="M246.494891 156.517625L246.494891 160.531926 199.583152 160.964236 199.082909 160.973499 181.253236 161.137159 180.827103 161.143335 175.407797 161.189654 175.247225 161.189654 142.212616 161.495359 87.9855854 161.99869 87.9392665 161.99869 74.1516866 162.128383 74.0034663 162.131471 12.7390576 162.693473 12.7390576 158.061587 27.1442223 149.415401 56.0471893 149.174543 56.664774 149.171455 56.868577 149.168367 57.4861618 149.159103 71.3632914 149.04485 71.9839641 149.041762 72.1815912 149.038674 72.799176 149.035586 77.2488742 148.998531 77.3662153 148.995443 88.3437845 148.902805 88.4333343 148.902805 174.092341 148.180231 176.189041 148.164791 176.39902 148.164791 177.998565 148.149352 189.624598 148.053626 190.242183 148.044362 190.813449 148.038187 191.109889 148.038187 194.725848 148.010395 195.442246 148.001131 196.402591 147.994956 199.413316 147.967164 204.190335 147.927021 204.807919 147.920845 205.882517 147.91467 206.083232 147.91467 229.153111 147.717042z"
                                opacity="0.675"
                            ></path>
                            <path
                                fill="#3F3D56"
                                d="M71.413 141.081v7.562c0 .732-.42 1.397-1.081 1.711-.241.117-.504.18-.772.185l-10.777.229a1.853 1.853 0 01-.834-.176 1.89 1.89 0 01-1.102-1.72v-7.79h14.566z"
                            ></path>
                            <path
                                fill="#000"
                                d="M71.413 141.081v7.562c0 .732-.42 1.397-1.081 1.711-.241.117-.504.18-.772.185l-10.777.229a1.853 1.853 0 01-.834-.176 1.89 1.89 0 01-1.102-1.72v-7.79h14.566z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M72.03 141.081v7.562c0 .732-.42 1.397-1.08 1.711-.241.117-.505.18-.772.185l-10.777.229a1.853 1.853 0 01-.834-.176 1.89 1.89 0 01-1.102-1.72v-7.79H72.03z"
                            ></path>
                            <path
                                fill="#3F3D56"
                                d="M190.242 141.078H204.808V150.805H190.242z"
                            ></path>
                            <path
                                fill="#000"
                                d="M190.242 141.078H204.808V150.805H190.242z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M189.625 141.078H204.191V150.805H189.625z"
                            ></path>
                            <path
                                fill="#000"
                                d="M72.027195 141.078006L72.027195 142.612704 57.4614584 143.001783 57.4614584 141.078006z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M97.737 88.185v51.939a1.235 1.235 0 01-1.189 1.22l-59.18 1.574h-.015a1.235 1.235 0 01-1.22-1.22V88.186c.008-.67.55-1.211 1.22-1.22h59.165c.67.009 1.21.55 1.22 1.22z"
                            ></path>
                            <path
                                fill="#000"
                                d="M37.368 86.965v55.953h-.015a1.235 1.235 0 01-1.22-1.22V88.186c.008-.67.55-1.211 1.22-1.22h.015z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#1D2A57"
                                d="M40.0671835 90.2816594L94.8778313 90.2816594 94.8778313 136.06013 40.0671835 137.2953z"
                            ></path>
                            <path
                                fill="#000"
                                d="M204.190335 141.078006L204.190335 143.057365 189.624598 142.668287 189.624598 141.078006z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M223.416 88.185v53.514a1.235 1.235 0 01-1.235 1.22l-59.193-1.575a1.235 1.235 0 01-1.189-1.22V88.185c.009-.67.55-1.211 1.22-1.22h59.165a1.235 1.235 0 011.232 1.22z"
                            ></path>
                            <path
                                fill="#000"
                                d="M223.416 88.185v53.514a1.235 1.235 0 01-1.235 1.22V86.964c.676 0 1.226.544 1.235 1.22z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#1D2A57"
                                d="M219.475558 90.2816594L164.66491 90.2816594 164.66491 136.06013 219.475558 137.2953z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M160.268 88.185v51.939a1.235 1.235 0 01-1.19 1.22l-59.18 1.574a1.235 1.235 0 01-1.234-1.22V88.186c.008-.67.55-1.211 1.22-1.22h59.164c.67.009 1.211.55 1.22 1.22z"
                            ></path>
                            <path
                                fill="#1D2A57"
                                d="M102.597641 90.2816594L157.408289 90.2816594 157.408289 136.06013 102.597641 137.2953z"
                            ></path>
                            <path
                                fill="#000"
                                d="M246.494891 156.517625L246.494891 160.531926 199.583152 160.964236 199.082909 160.973499 181.253236 161.137159 180.827103 161.143335 175.407797 161.189654 175.247225 161.189654 142.212616 161.495359 87.9855854 161.99869 87.9392665 161.99869 74.1516866 162.128383 74.0034663 162.131471 12.7390576 162.693473 12.7390576 158.061587 75.272603 157.650893 75.4208233 157.650893 88.0782231 157.564432 88.1368936 157.564432 175.549841 156.98699 175.639391 156.98699 175.691886 156.983902 175.837018 156.983902 177.316134 156.974638 177.76697 156.974638 200.768915 156.820242 202.127602 156.814066 202.430218 156.810978z"
                                opacity="0.1"
                            ></path>
                            <circle
                                cx="130.441"
                                cy="83.797"
                                r="15.85"
                                fill="#FBBEBE"
                            ></circle>
                            <path
                                fill="#FBBEBE"
                                d="M146.295 108.195s-30.725 3.705-27.791 0c1.062-1.34 1.355-5.312 1.296-9.835a116.153 116.153 0 00-.138-4.215c-.34-6.794-1.158-13.433-1.158-13.433s26.864-12.66 23.777-2.625c-1.236 4.055-.831 9.749.108 15.094.274 1.56.593 3.088.926 4.54.814 3.54 1.808 7.035 2.98 10.474z"
                            ></path>
                            <path
                                fill="#1657E2"
                                d="M136.268 106.938c3.555-.658 7.319-1.853 10.663-.476.88.362 1.69.89 2.59 1.192.902.303 1.89.377 2.83.59 3.297.747 5.971 3.258 7.688 6.176 1.717 2.918 2.606 6.219 3.477 9.486l2.13 7.976c1.854 6.933 3.706 13.924 4.136 21.09a752.719 752.719 0 01-77.458.442c1.68-3.193 3.397-6.58 3.255-10.19-.142-3.61-2.22-7.17-1.47-10.727.479-2.267 2.029-4.135 2.977-6.244 2.702-6.028.599-14.14 5.348-18.728 2.137-2.063 5.25-2.844 8.223-2.746 3.792.127 7.674 1.31 11.425 1.887 4.805.729 9.344 1.161 14.186.272z"
                            ></path>
                            <path
                                fill="#253260"
                                d="M87.976 162.348v-.26l54.23-.59 33.041-.308h.093v-.053l.309-4.15.132-1.853.507-6.982.398-5.527v-.136a3.28 3.28 0 00-.034-.763.093.093 0 000-.03 3.208 3.208 0 00-.617-1.433 3.255 3.255 0 00-2.91-1.235c-3.739.383-7.515.688-11.295.926a88.1 88.1 0 01-1.544.096c-24.376 1.448-48.533.275-61.604-.618l-.926-.068c-2.264-.16-4.157-.309-5.614-.435a3.255 3.255 0 00-3.526 3.14l-.21 6.843-.288 8.668-.136 4.434v.346l-.006-.012z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M176.782 143.431l-.38 4.734-.565 7.056-.142 1.766-.309 3.956-.018.197v.053l-.047.457.034-.457h-.09l-33.04.309-54.24.586v-.086l.152-4.434.309-8.665.197-5.76a1.964 1.964 0 012.112-1.898 510.32 510.32 0 007.914.559c4.015.253 9.052.525 14.823.747 16.057.618 37.734.824 58.325-.979.926-.08 1.883-.17 2.819-.26a1.933 1.933 0 011.34.371c.269.195.482.457.618.76a1.1 1.1 0 01.077.188c.093.256.13.529.11.8z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#000"
                                d="M72.03 147.658v.985c0 .732-.42 1.397-1.08 1.711l-12.383.238a1.89 1.89 0 01-1.102-1.72v-.926l14.565-.288z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#3F3D56"
                                d="M72.1815912 150.934659L56.0471893 151.243451 56.0471893 148.566222 72.1815912 148.257429z"
                            ></path>
                            <path
                                fill="#000"
                                d="M72.1815912 150.934659L56.0471893 151.243451 56.0471893 148.566222 72.1815912 148.257429z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M72.799176 150.934659L56.664774 151.243451 56.664774 148.566222 72.799176 148.257429z"
                            ></path>
                            <path
                                fill="#000"
                                d="M189.717 148.276v.985c0 .731.42 1.397 1.081 1.71l12.383.238a1.89 1.89 0 001.102-1.72v-.926l-14.566-.287z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#000"
                                d="M189.562839 151.552244L205.697241 151.861036 205.697241 149.183806 189.562839 148.875014z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M188.945255 151.552244L205.079657 151.861036 205.079657 149.183806 188.945255 148.875014z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M120.415 88.654c.025 2.162-.976 4.2-1.825 6.198a50.484 50.484 0 00-3.91 23.07c.226 3.396.797 6.793.226 10.159-.571 3.366-2.603 6.722-5.867 7.689 5.413 3.227 12.74 2.887 17.836-.822 2.714-1.976 4.737-4.733 6.716-7.445a30.218 30.218 0 01-4.11 13.819 31.938 31.938 0 0022.7-12.605c1.33-1.794 2.488-3.764 3-5.938.955-4.014-.376-8.186-1.392-12.197a82.157 82.157 0 01-1.905-10.19c-.133-1.1-.24-2.23.03-3.305.31-1.256 1.134-2.319 1.742-3.464 1.73-3.255 1.77-7.195.884-10.77-.887-3.577-2.622-6.875-4.342-10.132-1.377-2.613-2.872-5.346-5.392-6.88-1.574-.958-3.396-1.356-5.212-1.742l-7.834-1.677c-1.714-.367-3.477-.735-5.21-.466-2.924.457-4.983 2.57-6.793 4.737-1.417 1.686-4.882 4.851-5.126 7.059-.222 2.035 1.575 5.444 1.88 7.59.402 2.78.686 1.853 2.255 3.557.991 1.056 1.63 2.276 1.649 3.755z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationDeveloper
