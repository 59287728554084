import React, { Fragment } from "react"
import { SvgProps } from "../../interface/svg"

const IllustrationCity: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={SvgProps.width ? SvgProps.width : '1640px'}
            height={SvgProps.height ? SvgProps.height : '150px'}
            viewBox="0 0 1640 150"
        >
            <defs>
                <path id="ch" d="M20 48H36V52H20z"></path>
                <path id="bw" d="M0 32H20V36H0z"></path>
                <path id="bl" d="M0 40H20V44H0z"></path>
                <path id="ba" d="M0 48H20V52H0z"></path>
                <path id="ap" d="M20 48H36V52H20z"></path>
                <path id="ae" d="M24 68H28V72H24z"></path>
                <path id="t" d="M24 76H28V80H24z"></path>
                <path id="i" d="M32 68H36V72H32z"></path>
                <path id="a" d="M32 76H36V80H32z"></path>
                <path id="cg" d="M44 36H48V40H44z"></path>
                <path id="cf" d="M44 44H48V48H44z"></path>
                <path id="ce" d="M44 52H48V56H44z"></path>
                <path id="cd" d="M44 60H48V64H44z"></path>
                <path id="cc" d="M44 68H48V72H44z"></path>
                <path id="cb" d="M44 76H48V80H44z"></path>
                <path id="ca" d="M52 4H56V8H52z"></path>
                <path id="bz" d="M52 12H56V16H52z"></path>
                <path id="by" d="M52 20H56V24H52z"></path>
                <path id="bx" d="M52 28H56V32H52z"></path>
                <path id="bv" d="M52 36H56V40H52z"></path>
                <path id="bu" d="M52 44H56V48H52z"></path>
                <path id="bt" d="M52 52H56V56H52z"></path>
                <path id="bs" d="M52 60H56V64H52z"></path>
                <path id="br" d="M52 68H56V72H52z"></path>
                <path id="bq" d="M52 76H56V80H52z"></path>
                <path id="bp" d="M0 32H20V36H0z"></path>
                <path id="bo" d="M0 40H20V44H0z"></path>
                <path id="bn" d="M0 48H20V52H0z"></path>
                <path id="bm" d="M20 32H36V36H20z"></path>
                <path id="bk" d="M20 40H36V44H20z"></path>
                <path id="bj" d="M20 48H36V52H20z"></path>
                <path id="bi" d="M48 36H68V56H48z"></path>
                <path id="bh" d="M4 40H8V44H4z"></path>
                <path id="bg" d="M4 48H8V52H4z"></path>
                <path id="bf" d="M12 40H16V44H12z"></path>
                <path id="be" d="M40 40H44V44H40z"></path>
                <path id="bd" d="M12 48H16V52H12z"></path>
                <path id="bc" d="M40 48H44V52H40z"></path>
                <path id="bb" d="M4 40H8V44H4z"></path>
                <path id="az" d="M4 48H8V52H4z"></path>
                <path id="ay" d="M12 40H16V44H12z"></path>
                <path id="ax" d="M12 48H16V52H12z"></path>
                <path id="aw" d="M4 28H8V32H4z"></path>
                <path id="av" d="M20 48H36V52H20z"></path>
                <path id="au" d="M0 32H20V36H0z"></path>
                <path id="at" d="M0 40H20V44H0z"></path>
                <path id="as" d="M0 48H20V52H0z"></path>
                <path id="ar" d="M20 48H36V52H20z"></path>
                <path id="aq" d="M24 68H28V72H24z"></path>
                <path id="ao" d="M24 76H28V80H24z"></path>
                <path id="an" d="M32 68H36V72H32z"></path>
                <path id="am" d="M32 76H36V80H32z"></path>
                <path id="al" d="M44 36H48V40H44z"></path>
                <path id="ak" d="M44 44H48V48H44z"></path>
                <path id="aj" d="M44 52H48V56H44z"></path>
                <path id="ai" d="M44 60H48V64H44z"></path>
                <path id="ah" d="M44 68H48V72H44z"></path>
                <path id="ag" d="M44 76H48V80H44z"></path>
                <path id="af" d="M52 4H56V8H52z"></path>
                <path id="ad" d="M52 12H56V16H52z"></path>
                <path id="ac" d="M52 20H56V24H52z"></path>
                <path id="ab" d="M52 28H56V32H52z"></path>
                <path id="aa" d="M52 36H56V40H52z"></path>
                <path id="z" d="M52 44H56V48H52z"></path>
                <path id="y" d="M52 52H56V56H52z"></path>
                <path id="x" d="M52 60H56V64H52z"></path>
                <path id="w" d="M52 68H56V72H52z"></path>
                <path id="v" d="M52 76H56V80H52z"></path>
                <path id="u" d="M0 32H20V36H0z"></path>
                <path id="s" d="M0 40H20V44H0z"></path>
                <path id="r" d="M0 48H20V52H0z"></path>
                <path id="q" d="M20 32H36V36H20z"></path>
                <path id="p" d="M20 40H36V44H20z"></path>
                <path id="o" d="M20 48H36V52H20z"></path>
                <path id="n" d="M48 36H68V56H48z"></path>
                <path id="m" d="M4 40H8V44H4z"></path>
                <path id="l" d="M4 48H8V52H4z"></path>
                <path id="k" d="M12 40H16V44H12z"></path>
                <path id="j" d="M40 40H44V44H40z"></path>
                <path id="h" d="M12 48H16V52H12z"></path>
                <path id="g" d="M40 48H44V52H40z"></path>
                <path id="f" d="M4 40H8V44H4z"></path>
                <path id="e" d="M4 48H8V52H4z"></path>
                <path id="d" d="M12 40H16V44H12z"></path>
                <path id="c" d="M12 48H16V52H12z"></path>
                <path id="b" d="M4 28H8V32H4z"></path>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#1657E2"
                    d="M100 58c23.775 0 27.863 16 43.713 16h23.28c23.775 0 6.934-16 32.195-16s8.42-16 31.7-16h31.205c12.919 0 7.925 16 31.7 16h47.55c23.775 0 15.85 16 39.625 16h31.7c23.775 0 7.925 16 35.663 16h39.625c27.738 0 11.888-16 35.663-16s7.925-16 39.625-16h31.7c23.775 0 15.85 16 39.625 16h15.85c15.85 0 7.925 16 31.7 16h15.85c15.85 0 7.925-16 31.7-16h15.85c23.775 0 7.925 16 31.7 16s7.925 16 31.7 16h47.55c24.27 0 15.85-16 40.12-16h31.7c23.775 0 14.859-16 47.055-16h55.97c35.167 0 23.28 16 47.055 16h95.1c23.775 0 7.925-16 39.625-16h101.044c23.775 0 30.723 16 49.531 16h47.55c32.638 0 15.85 16 39.625 16h39.625c23.775 0 18.803-16 31.7-16H1540v58H100V58z"
                    opacity="0.054"
                    transform="translate(85 -607) translate(-85 607)"
                ></path>
                <g
                    opacity="0.501"
                    transform="translate(85 -607) translate(-85 607)"
                >
                    <g transform="translate(196.5 60)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#091E43" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#091E43" d="M24 48H28V52H24z"></path>
                        <path fill="#C3D0D8" d="M4 56H8V60H4z"></path>
                        <path fill="#091E43" d="M24 56H28V60H24z"></path>
                        <path fill="#C3D0D8" d="M4 64H8V68H4z"></path>
                        <path fill="#091E43" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#091E43" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#091E43" d="M32 48H36V52H32z"></path>
                        <path fill="#C3D0D8" d="M12 56H16V60H12z"></path>
                        <path fill="#091E43" d="M32 56H36V60H32z"></path>
                        <path fill="#C3D0D8" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(208 72)">
                        <path fill="#D0DDF9" d="M0 36H28V72H0z"></path>
                        <path fill="#D0DDF9" d="M12 24H40V36H12z"></path>
                        <path fill="#D0DDF9" d="M24 12H52V24H24z"></path>
                        <path fill="#D0DDF9" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#091E43" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#8AABF0"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#091E43" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#091E43" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 572 72)">
                        <path fill="#D0DDF9" d="M0 36H28V72H0z"></path>
                        <path fill="#D0DDF9" d="M12 24H40V36H12z"></path>
                        <path fill="#D0DDF9" d="M24 12H52V24H24z"></path>
                        <path fill="#D0DDF9" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#091E43" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#8AABF0"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#091E43" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#091E43" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 520 60)">
                        <path fill="#B9CCF6" d="M0 36H28V72H0z"></path>
                        <path fill="#B9CCF6" d="M12 24H40V36H12z"></path>
                        <path fill="#B9CCF6" d="M24 12H52V24H24z"></path>
                        <path fill="#B9CCF6" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#8AABF0" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#E7EDFC"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#E7EDFC" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#8AABF0" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="translate(160.5 86)">
                        <path fill="#D0DDF9" d="M0 2H28V62H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H48V62H28z"></path>
                        <path fill="#8AABF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AABF0" d="M28 0H48V2H28z"></path>
                        <path fill="#C3D0D8" d="M4 54H8V58H4z"></path>
                        <path fill="#E7EDFC" d="M4 30H8V34H4z"></path>
                        <path fill="#C3D0D8" d="M4 46H8V50H4z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#C3D0D8" d="M12 46H16V50H12z"></path>
                        <path fill="#E7EDFC" d="M12 22H16V26H12z"></path>
                        <path fill="#C3D0D8" d="M4 38H8V42H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#C3D0D8" d="M12 38H16V42H12z"></path>
                        <path fill="#E7EDFC" d="M12 30H16V34H12z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#091E43" d="M12 54H16V62H12z"></path>
                        <path fill="#C3D0D8" d="M20 54H24V58H20z"></path>
                        <path fill="#E7EDFC" d="M20 30H24V34H20z"></path>
                        <path fill="#8AABF0" d="M32 22H36V26H32z"></path>
                        <path fill="#8AABF0" d="M40 22H44V26H40z"></path>
                        <path fill="#C3D0D8" d="M20 46H24V50H20z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AABF0" d="M32 14H36V18H32z"></path>
                        <path fill="#8AABF0" d="M40 14H44V18H40z"></path>
                        <path fill="#C3D0D8" d="M20 38H24V42H20z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AABF0" d="M32 6H36V10H32z"></path>
                        <path fill="#8AABF0" d="M40 6H44V10H40z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 600 72)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#739AED" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#739AED" d="M24 48H28V52H24z"></path>
                        <path fill="#E8EEFC" d="M4 56H8V60H4z"></path>
                        <path fill="#739AED" d="M24 56H28V60H24z"></path>
                        <path fill="#E8EEFC" d="M4 64H8V68H4z"></path>
                        <path fill="#739AED" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#739AED" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#739AED" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M12 56H16V60H12z"></path>
                        <path fill="#739AED" d="M32 56H36V60H32z"></path>
                        <path fill="#E8EEFC" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(48.5 98)">
                        <path fill="#B8CCF6" d="M0 26H36V38H0z"></path>
                        <path fill="#091E43" d="M16 30H20V38H16z"></path>
                        <path fill="#C3D0D8" d="M4 30H12V34H4z"></path>
                        <path fill="#C3D0D8" d="M24 30H32V34H24z"></path>
                        <path fill="#B8CCF6" d="M8 14H44V26H8z"></path>
                        <path fill="#B8CCF6" d="M16 2H52V14H16z"></path>
                        <path fill="#A1BBF3" d="M52 2H64V14H52z"></path>
                        <path fill="#A1BBF3" d="M44 14H64V26H44z"></path>
                        <path fill="#A1BBF3" d="M36 26H64V38H36z"></path>
                        <path fill="#E8EEFC" d="M12 18H16V22H12z"></path>
                        <path fill="#E8EEFC" d="M20 18H24V22H20z"></path>
                        <path fill="#E8EEFC" d="M28 18H32V22H28z"></path>
                        <path fill="#E8EEFC" d="M36 18H40V22H36z"></path>
                        <path fill="#E8EEFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E8EEFC" d="M28 6H32V10H28z"></path>
                        <path fill="#E8EEFC" d="M36 6H40V10H36z"></path>
                        <path fill="#E8EEFC" d="M44 6H48V10H44z"></path>
                        <path fill="#091E43" d="M56 6H60V10H56z"></path>
                        <path fill="#091E43" d="M56 18H60V22H56z"></path>
                        <path fill="#091E43" d="M56 30H60V34H56z"></path>
                        <path fill="#739AED" d="M48 18H52V22H48z"></path>
                        <path fill="#091E43" d="M48 30H52V34H48z"></path>
                        <path fill="#091E43" d="M40 30H44V34H40z"></path>
                        <path fill="#739AED" d="M16 0H52V2H16z"></path>
                        <path fill="#739AED" d="M52 0H64V2H52z"></path>
                    </g>
                    <g transform="translate(150 100)">
                        <path fill="#D0DDF9" d="M0 24H36V48H0z"></path>
                        <path fill="#B9CCF6" d="M36 24H48V48H36z"></path>
                        <path fill="#8AAAF0" d="M48 24H60V36H48z"></path>
                        <path fill="#8AAAF0" d="M60 24H72V36H60z"></path>
                        <path fill="#8AAAF0" d="M72 24H84V36H72z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6 32a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M60 24L60 48 48 48 48 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M72 24L72 48 60 48 60 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M84 24L84 48 72 48 72 36z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6 40a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#739AED"
                            d="M54 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M66 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M78 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path fill="#B9CCF6" d="M30 0L32 0 32 24 28 24z"></path>
                        <path fill="#B9CCF6" d="M16 0L18 0 18 24 14 24z"></path>
                        <path fill="#8AAAF0" d="M32 0L34 0 36 24 32 24z"></path>
                        <path fill="#8AAAF0" d="M18 0L20 0 22 24 18 24z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 792 90)">
                        <path fill="#D0DDF9" d="M0 24H36V48H0z"></path>
                        <path fill="#B9CCF6" d="M36 24H48V48H36z"></path>
                        <path fill="#8AAAF0" d="M48 24H60V36H48z"></path>
                        <path fill="#8AAAF0" d="M60 24H72V36H60z"></path>
                        <path fill="#8AAAF0" d="M72 24H84V36H72z"></path>
                        <path
                            fill="#E8EEFC"
                            d="M6 32a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M60 24L60 48 48 48 48 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M72 24L72 48 60 48 60 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M84 24L84 48 72 48 72 36z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M6 40a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#739AED"
                            d="M54 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M66 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M78 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path fill="#B9CCF6" d="M30 0L32 0 32 24 28 24z"></path>
                        <path fill="#B9CCF6" d="M16 0L18 0 18 24 14 24z"></path>
                        <path fill="#8AAAF0" d="M32 0L34 0 36 24 32 24z"></path>
                        <path fill="#8AAAF0" d="M18 0L20 0 22 24 18 24z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 566 68)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <path fill="#E7EDFC" d="M0 32H20V36H0z"></path>
                        <path fill="#E7EDFC" d="M0 40H20V44H0z"></path>
                        <path fill="#E7EDFC" d="M0 48H20V52H0z"></path>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <path fill="#739AED" d="M20 32H36V36H20z"></path>
                        <path fill="#739AED" d="M20 40H36V44H20z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ch"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ch"
                            ></use>
                        </g>
                    </g>
                    <g transform="matrix(-1 0 0 1 456 88)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bw"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bw"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bl"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bl"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ba"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#ba"
                            ></use>
                        </g>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <path fill="#739AED" d="M20 32H36V36H20z"></path>
                        <path fill="#739AED" d="M20 40H36V44H20z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ap"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ap"
                            ></use>
                        </g>
                    </g>
                    <g transform="translate(504 86)">
                        <path fill="#D0DDF9" d="M32 12H44V52H32z"></path>
                        <path fill="#B9CCF6" d="M44 12H56V52H44z"></path>
                        <path fill="#B9CCF6" d="M44 0L44 12 32 12z"></path>
                        <circle cx="38" cy="18" r="2" fill="#E7EDFC"></circle>
                        <circle cx="50" cy="18" r="2" fill="#8AABF0"></circle>
                        <path fill="#8AABF0" d="M44 0L56 12 44 12z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M0 32L10 24 20 32 20 52 0 52z"
                        ></path>
                        <path fill="#B9CCF6" d="M20 32H48V52H20z"></path>
                        <path
                            fill="#8AABF0"
                            d="M10 24L38 24 48 32 20 32z"
                        ></path>
                        <path
                            fill="#E7EDFC"
                            d="M10 40a2 2 0 012 2v10H8V42a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AABF0"
                            d="M26 36a2 2 0 012 2v10h-4V38a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AABF0"
                            d="M34 36a2 2 0 012 2v10h-4V38a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AABF0"
                            d="M42 36a2 2 0 012 2v10h-4V38a2 2 0 012-2z"
                        ></path>
                        <rect
                            width="4"
                            height="4"
                            x="8"
                            y="32"
                            fill="#E7EDFC"
                            rx="2"
                        ></rect>
                    </g>
                    <g transform="translate(264 60)">
                        <path fill="#B9CCF6" d="M0 32H20V88H0z"></path>
                        <path
                            fill="#739AED"
                            d="M20 20L40 20 20 32 0 32z"
                        ></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 32L40 20 40 32 40 88 20 88z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 36H8V84H4z"></path>
                        <path fill="#739AED" d="M24 36H28V40H24z"></path>
                        <path fill="#739AED" d="M24 44H28V48H24z"></path>
                        <path fill="#739AED" d="M24 52H28V56H24z"></path>
                        <path fill="#739AED" d="M24 60H28V64H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ae"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ae"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#t"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#t"
                            ></use>
                        </g>
                        <path fill="#E8EEFC" d="M12 36H16V84H12z"></path>
                        <path fill="#739AED" d="M32 36H36V40H32z"></path>
                        <path fill="#739AED" d="M32 44H36V48H32z"></path>
                        <path fill="#739AED" d="M32 52H36V56H32z"></path>
                        <path fill="#739AED" d="M32 60H36V64H32z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#i"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#i"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#a"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#a"
                            ></use>
                        </g>
                        <path fill="#B9CCF6" d="M20 0H40V20H20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M40 -3.1508e-13L60 -3.1508e-13 60 88 40 88z"
                        ></path>
                        <path fill="#E8EEFC" d="M24 4H28V20H24z"></path>
                        <path fill="#739AED" d="M44 4H48V8H44z"></path>
                        <path fill="#739AED" d="M44 12H48V16H44z"></path>
                        <path fill="#739AED" d="M44 20H48V24H44z"></path>
                        <path fill="#739AED" d="M44 28H48V32H44z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#cg"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#cg"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#cf"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#cf"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ce"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ce"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#cd"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#cd"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#cc"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#cc"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#cb"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#cb"
                            ></use>
                        </g>
                        <path fill="#E8EEFC" d="M32 4H36V20H32z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ca"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ca"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bz"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bz"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#by"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#by"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bx"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bx"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bv"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bv"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bu"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bu"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bt"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bt"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bs"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bs"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#br"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#br"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bq"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bq"
                            ></use>
                        </g>
                    </g>
                    <g transform="translate(228 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(40 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(388)">
                        <path fill="#D0DDF9" d="M0 4H40V128H0z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M8 0H32V4H8z"
                            transform="matrix(1 0 0 -1 0 4)"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M32 0H48V4H32z"
                            transform="matrix(1 0 0 -1 0 4)"
                        ></path>
                        <path fill="#B9CCF6" d="M40 4H56V128H40z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V120H4z"></path>
                        <path fill="#E7EDFC" d="M16 12H24V120H16z"></path>
                        <path fill="#E7EDFC" d="M28 12H36V120H28z"></path>
                        <path fill="#B9CCF6" d="M4 12H12V40H4z"></path>
                        <path fill="#B9CCF6" d="M16 12H24V40H16z"></path>
                        <path fill="#B9CCF6" d="M28 12H36V40H28z"></path>
                        <path fill="#8AABF0" d="M44 12H52V120H44z"></path>
                    </g>
                    <g transform="translate(312 24)">
                        <path fill="#D0DDF9" d="M0 0H48V124H0z"></path>
                        <path fill="#B9CCF6" d="M48 0H68V124H48z"></path>
                        <path fill="#E7EDFC" d="M4 16H42V116H4z"></path>
                        <path fill="#fff" d="M4 44L4 32 42 56 42 68z"></path>
                        <path fill="#fff" d="M4 53L4 48 42 72 42 77z"></path>
                        <path fill="#8AABF0" d="M52 16H64V116H52z"></path>
                        <path fill="#8AABF0" d="M4 6H20V12H4z"></path>
                    </g>
                    <g transform="translate(408 64)">
                        <path
                            fill="#B8CCF6"
                            d="M0 1.0805e-13L28 1.0805e-13 28 68 0 68z"
                        ></path>
                        <path fill="#A1BBF3" d="M28 0H40V68H28z"></path>
                        <path fill="#E8EEFC" d="M4 8H24V12H4z"></path>
                        <path fill="#E8EEFC" d="M4 16H8V56H4z"></path>
                        <path fill="#E8EEFC" d="M20 16H24V56H20z"></path>
                        <path fill="#E8EEFC" d="M12 16H16V56H12z"></path>
                        <path fill="#739AED" d="M32 8H36V12H32z"></path>
                        <path fill="#739AED" d="M32 16H36V48H32z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 504 72)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#739AED" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#739AED" d="M24 48H28V52H24z"></path>
                        <path fill="#E8EEFC" d="M4 56H8V60H4z"></path>
                        <path fill="#739AED" d="M24 56H28V60H24z"></path>
                        <path fill="#E8EEFC" d="M4 64H8V68H4z"></path>
                        <path fill="#739AED" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#739AED" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#739AED" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M12 56H16V60H12z"></path>
                        <path fill="#739AED" d="M32 56H36V60H32z"></path>
                        <path fill="#E8EEFC" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(328 72)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bp"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bp"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bo"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bo"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bn"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bn"
                            ></use>
                        </g>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bm"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bm"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bk"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bk"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bj"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#bj"
                            ></use>
                        </g>
                    </g>
                    <g transform="translate(288 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bi"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.5"
                                xlinkHref="#bi"
                            ></use>
                        </g>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bh"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bh"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bg"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bg"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bf"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bf"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#be"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#be"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bd"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bd"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bc"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bc"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(92.5 92)">
                        <path fill="#A1BBF3" d="M32 0H44V36H32z"></path>
                        <path fill="#A1BBF3" d="M56 8H68V36H56z"></path>
                        <path fill="#A1BBF3" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#B8CCF6" d="M12 0H32V36H12z"></path>
                        <path fill="#B8CCF6" d="M0 36H20V56H0z"></path>
                        <path fill="#B8CCF6" d="M28 36H48V56H28z"></path>
                        <path fill="#B8CCF6" d="M36 8H56V36H36z"></path>
                        <path fill="#E8EEFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E8EEFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E8EEFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E8EEFC" d="M16 20H20V24H16z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E8EEFC" d="M16 28H20V32H16z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#E8EEFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E8EEFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E8EEFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M48 12H52V16H48z"></path>
                        <path fill="#739AED" d="M60 12H64V16H60z"></path>
                        <path fill="#E8EEFC" d="M24 20H28V24H24z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#E8EEFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E8EEFC" d="M48 20H52V24H48z"></path>
                        <path fill="#739AED" d="M60 20H64V24H60z"></path>
                        <path fill="#E8EEFC" d="M24 28H28V32H24z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#E8EEFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E8EEFC" d="M48 28H52V32H48z"></path>
                        <path fill="#739AED" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(344 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#bb"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#bb"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#az"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#az"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ay"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#ay"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ax"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#ax"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(600 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <path fill="#E7EDFC" d="M4 40H8V44H4z"></path>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <path fill="#E7EDFC" d="M4 48H8V52H4z"></path>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <path fill="#E7EDFC" d="M12 40H16V44H12z"></path>
                        <path fill="#E7EDFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <path fill="#E7EDFC" d="M12 48H16V52H12z"></path>
                        <path fill="#E7EDFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(276 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(140 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(432 112)">
                        <path fill="#B9CCF6" d="M28 0H40V36H28z"></path>
                        <path fill="#D0DDF9" d="M0 0H28V36H0z"></path>
                        <path fill="#E7EDFC" d="M4 4H8V8H4z"></path>
                        <path fill="#E7EDFC" d="M4 12H8V16H4z"></path>
                        <path fill="#E7EDFC" d="M4 20H8V24H4z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#aw"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#aw"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M12 4H16V8H12z"></path>
                        <path fill="#E7EDFC" d="M20 4H24V8H20z"></path>
                        <path fill="#8AABF0" d="M32 4H36V8H32z"></path>
                        <path fill="#E7EDFC" d="M12 12H16V16H12z"></path>
                        <path fill="#E7EDFC" d="M20 12H24V16H20z"></path>
                        <path fill="#8AABF0" d="M32 12H36V16H32z"></path>
                        <path fill="#8AABF0" d="M32 20H36V24H32z"></path>
                        <path fill="#E7EDFC" d="M12 20H16V24H12z"></path>
                        <path fill="#E7EDFC" d="M20 20H24V24H20z"></path>
                        <path fill="#E7EDFC" d="M12 28H16V32H12z"></path>
                        <path fill="#E7EDFC" d="M20 28H24V32H20z"></path>
                    </g>
                    <g transform="translate(328 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#B8CCF6" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(400 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(464 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(496 128)">
                        <path fill="#B8CCF6" d="M0 8H44V20H0z"></path>
                        <path fill="#739AED" d="M6 0L50 0 44 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M44 8L50 0 56 8 56 20 44 20z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 12H28V20H24z"></path>
                        <path fill="#E7EDFC" d="M32 12H40V16H32z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V16H4z"></path>
                        <path fill="#739AED" d="M48 12H52V16H48z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V20H16z"></path>
                    </g>
                    <g transform="translate(736 128)">
                        <path fill="#B8CCF6" d="M0 8H44V20H0z"></path>
                        <path fill="#739AED" d="M6 0L50 0 44 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M44 8L50 0 56 8 56 20 44 20z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 12H28V20H24z"></path>
                        <path fill="#E7EDFC" d="M32 12H40V16H32z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V16H4z"></path>
                        <path fill="#739AED" d="M48 12H52V16H48z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V20H16z"></path>
                    </g>
                    <g transform="translate(0 127)">
                        <path fill="#D0DDF9" d="M0 9H44V21H0z"></path>
                        <path fill="#8AAAF0" d="M6 1L50 1 44 9 0 9z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M44 9L50 1 56 9 56 21 44 21z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 13H28V21H24z"></path>
                        <path fill="#E7EDFC" d="M32 13H40V17H32z"></path>
                        <path fill="#E7EDFC" d="M4 13H12V17H4z"></path>
                        <path fill="#8AAAF0" d="M48 13H52V17H48z"></path>
                        <path fill="#E7EDFC" d="M16 13H20V21H16z"></path>
                        <path fill="#D0DDF9" d="M8 0L11 0 11 5 8 5z"></path>
                        <path fill="#D0DDF9" d="M36 0L39 0 39 5 36 5z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M11 -2.2898e-15L12.5 -2.2898e-15 12.5 2.9957 11 5z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M39 -2.2898e-15L40.5 -2.2898e-15 40.5 2.9957 39 5z"
                        ></path>
                    </g>
                    <g transform="translate(68 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(548 110)">
                        <path fill="#D0DDF9" d="M0 26H36V38H0z"></path>
                        <path fill="#8AABF0" d="M16 30H20V38H16z"></path>
                        <path fill="#E8EEFC" d="M4 30H12V34H4z"></path>
                        <path fill="#E8EEFC" d="M24 30H32V34H24z"></path>
                        <path fill="#D0DDF9" d="M8 14H44V26H8z"></path>
                        <path fill="#D0DDF9" d="M16 2H52V14H16z"></path>
                        <path fill="#B9CCF6" d="M52 2H64V14H52z"></path>
                        <path fill="#B9CCF6" d="M44 14H64V26H44z"></path>
                        <path fill="#B9CCF6" d="M36 26H64V38H36z"></path>
                        <path fill="#E8EEFC" d="M12 18H16V22H12z"></path>
                        <path fill="#E8EEFC" d="M20 18H24V22H20z"></path>
                        <path fill="#E8EEFC" d="M28 18H32V22H28z"></path>
                        <path fill="#E8EEFC" d="M36 18H40V22H36z"></path>
                        <path fill="#E8EEFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E8EEFC" d="M28 6H32V10H28z"></path>
                        <path fill="#E8EEFC" d="M36 6H40V10H36z"></path>
                        <path fill="#E8EEFC" d="M44 6H48V10H44z"></path>
                        <path fill="#8AABF0" d="M56 6H60V10H56z"></path>
                        <path fill="#8AABF0" d="M56 18H60V22H56z"></path>
                        <path fill="#091E43" d="M56 30H60V34H56z"></path>
                        <path fill="#8AABF0" d="M48 18H52V22H48z"></path>
                        <path fill="#8AABF0" d="M48 30H52V34H48z"></path>
                        <path fill="#8AABF0" d="M40 30H44V34H40z"></path>
                        <path fill="#8AABF0" d="M16 0H52V2H16z"></path>
                        <path fill="#8AABF0" d="M52 0H64V2H52z"></path>
                    </g>
                    <g transform="translate(660 118)">
                        <path fill="#B8CCF6" d="M0 2H28V30H0z"></path>
                        <path fill="#A1BBF3" d="M28 2H40V30H28z"></path>
                        <path fill="#739AED" d="M0 0H28V2H0z"></path>
                        <path fill="#739AED" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#739AED" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#739AED" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#739AED" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#739AED" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(788 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(604 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(696 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                </g>
                <g
                    opacity="0.498"
                    transform="translate(85 -607) translate(-85 607) translate(820)"
                >
                    <g transform="translate(196.5 60)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#091E43" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#091E43" d="M24 48H28V52H24z"></path>
                        <path fill="#C3D0D8" d="M4 56H8V60H4z"></path>
                        <path fill="#091E43" d="M24 56H28V60H24z"></path>
                        <path fill="#C3D0D8" d="M4 64H8V68H4z"></path>
                        <path fill="#091E43" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#091E43" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#091E43" d="M32 48H36V52H32z"></path>
                        <path fill="#C3D0D8" d="M12 56H16V60H12z"></path>
                        <path fill="#091E43" d="M32 56H36V60H32z"></path>
                        <path fill="#C3D0D8" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(208 72)">
                        <path fill="#D0DDF9" d="M0 36H28V72H0z"></path>
                        <path fill="#D0DDF9" d="M12 24H40V36H12z"></path>
                        <path fill="#D0DDF9" d="M24 12H52V24H24z"></path>
                        <path fill="#D0DDF9" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#091E43" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#8AABF0"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#091E43" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#091E43" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 572 72)">
                        <path fill="#D0DDF9" d="M0 36H28V72H0z"></path>
                        <path fill="#D0DDF9" d="M12 24H40V36H12z"></path>
                        <path fill="#D0DDF9" d="M24 12H52V24H24z"></path>
                        <path fill="#D0DDF9" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#091E43" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#8AABF0"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#091E43" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#091E43" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 520 60)">
                        <path fill="#B9CCF6" d="M0 36H28V72H0z"></path>
                        <path fill="#B9CCF6" d="M12 24H40V36H12z"></path>
                        <path fill="#B9CCF6" d="M24 12H52V24H24z"></path>
                        <path fill="#B9CCF6" d="M36 0H64V12H36z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M64 12V0h12v72H28V36h12V24h12V12h12z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 40H12V44H4z"></path>
                        <path fill="#E8EEFC" d="M16 28H24V32H16z"></path>
                        <path fill="#E8EEFC" d="M28 16H36V20H28z"></path>
                        <path fill="#E8EEFC" d="M40 4H48V8H40z"></path>
                        <path fill="#E7EDFC" d="M4 48H12V52H4z"></path>
                        <path fill="#C3D0D8" d="M4 56H12V60H4z"></path>
                        <path fill="#E8EEFC" d="M16 40H24V44H16z"></path>
                        <path fill="#E8EEFC" d="M28 28H36V32H28z"></path>
                        <path fill="#E8EEFC" d="M40 16H48V20H40z"></path>
                        <path fill="#E8EEFC" d="M52 4H60V8H52z"></path>
                        <path fill="#8AABF0" d="M32 40H40V44H32z"></path>
                        <path fill="#8AABF0" d="M44 40H52V44H44z"></path>
                        <path fill="#8AABF0" d="M56 24H64V28H56z"></path>
                        <path fill="#8AABF0" d="M44 28H52V32H44z"></path>
                        <path fill="#8AABF0" d="M56 16H64V20H56z"></path>
                        <path
                            fill="#E7EDFC"
                            d="M70 4a2 2 0 012 2v6h-4V6a2 2 0 012-2z"
                        ></path>
                        <path fill="#E7EDFC" d="M68 16H72V36H68z"></path>
                        <path fill="#E7EDFC" d="M16 48H24V52H16z"></path>
                        <path fill="#091E43" d="M32 48H40V52H32z"></path>
                        <path fill="#8AABF0" d="M56 32H64V36H56z"></path>
                        <path fill="#C3D0D8" d="M16 56H24V60H16z"></path>
                        <path fill="#091E43" d="M32 56H40V60H32z"></path>
                        <path fill="#C3D0D8" d="M16 64H24V68H16z"></path>
                        <path fill="#C3D0D8" d="M4 64H12V68H4z"></path>
                    </g>
                    <g transform="translate(160.5 86)">
                        <path fill="#D0DDF9" d="M0 2H28V62H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H48V62H28z"></path>
                        <path fill="#8AABF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AABF0" d="M28 0H48V2H28z"></path>
                        <path fill="#C3D0D8" d="M4 54H8V58H4z"></path>
                        <path fill="#E7EDFC" d="M4 30H8V34H4z"></path>
                        <path fill="#C3D0D8" d="M4 46H8V50H4z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#C3D0D8" d="M12 46H16V50H12z"></path>
                        <path fill="#E7EDFC" d="M12 22H16V26H12z"></path>
                        <path fill="#C3D0D8" d="M4 38H8V42H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#C3D0D8" d="M12 38H16V42H12z"></path>
                        <path fill="#E7EDFC" d="M12 30H16V34H12z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#091E43" d="M12 54H16V62H12z"></path>
                        <path fill="#C3D0D8" d="M20 54H24V58H20z"></path>
                        <path fill="#E7EDFC" d="M20 30H24V34H20z"></path>
                        <path fill="#8AABF0" d="M32 22H36V26H32z"></path>
                        <path fill="#8AABF0" d="M40 22H44V26H40z"></path>
                        <path fill="#C3D0D8" d="M20 46H24V50H20z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AABF0" d="M32 14H36V18H32z"></path>
                        <path fill="#8AABF0" d="M40 14H44V18H40z"></path>
                        <path fill="#C3D0D8" d="M20 38H24V42H20z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AABF0" d="M32 6H36V10H32z"></path>
                        <path fill="#8AABF0" d="M40 6H44V10H40z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 600 72)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#739AED" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#739AED" d="M24 48H28V52H24z"></path>
                        <path fill="#E8EEFC" d="M4 56H8V60H4z"></path>
                        <path fill="#739AED" d="M24 56H28V60H24z"></path>
                        <path fill="#E8EEFC" d="M4 64H8V68H4z"></path>
                        <path fill="#739AED" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#739AED" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#739AED" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M12 56H16V60H12z"></path>
                        <path fill="#739AED" d="M32 56H36V60H32z"></path>
                        <path fill="#E8EEFC" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(48.5 98)">
                        <path fill="#B8CCF6" d="M0 26H36V38H0z"></path>
                        <path fill="#091E43" d="M16 30H20V38H16z"></path>
                        <path fill="#C3D0D8" d="M4 30H12V34H4z"></path>
                        <path fill="#C3D0D8" d="M24 30H32V34H24z"></path>
                        <path fill="#B8CCF6" d="M8 14H44V26H8z"></path>
                        <path fill="#B8CCF6" d="M16 2H52V14H16z"></path>
                        <path fill="#A1BBF3" d="M52 2H64V14H52z"></path>
                        <path fill="#A1BBF3" d="M44 14H64V26H44z"></path>
                        <path fill="#A1BBF3" d="M36 26H64V38H36z"></path>
                        <path fill="#E8EEFC" d="M12 18H16V22H12z"></path>
                        <path fill="#E8EEFC" d="M20 18H24V22H20z"></path>
                        <path fill="#E8EEFC" d="M28 18H32V22H28z"></path>
                        <path fill="#E8EEFC" d="M36 18H40V22H36z"></path>
                        <path fill="#E8EEFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E8EEFC" d="M28 6H32V10H28z"></path>
                        <path fill="#E8EEFC" d="M36 6H40V10H36z"></path>
                        <path fill="#E8EEFC" d="M44 6H48V10H44z"></path>
                        <path fill="#091E43" d="M56 6H60V10H56z"></path>
                        <path fill="#091E43" d="M56 18H60V22H56z"></path>
                        <path fill="#091E43" d="M56 30H60V34H56z"></path>
                        <path fill="#739AED" d="M48 18H52V22H48z"></path>
                        <path fill="#091E43" d="M48 30H52V34H48z"></path>
                        <path fill="#091E43" d="M40 30H44V34H40z"></path>
                        <path fill="#739AED" d="M16 0H52V2H16z"></path>
                        <path fill="#739AED" d="M52 0H64V2H52z"></path>
                    </g>
                    <g transform="translate(150 100)">
                        <path fill="#D0DDF9" d="M0 24H36V48H0z"></path>
                        <path fill="#B9CCF6" d="M36 24H48V48H36z"></path>
                        <path fill="#8AAAF0" d="M48 24H60V36H48z"></path>
                        <path fill="#8AAAF0" d="M60 24H72V36H60z"></path>
                        <path fill="#8AAAF0" d="M72 24H84V36H72z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6 32a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M60 24L60 48 48 48 48 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M72 24L72 48 60 48 60 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M84 24L84 48 72 48 72 36z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6 40a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M14 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M22 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M30 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#739AED"
                            d="M54 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M66 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M78 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path fill="#B9CCF6" d="M30 0L32 0 32 24 28 24z"></path>
                        <path fill="#B9CCF6" d="M16 0L18 0 18 24 14 24z"></path>
                        <path fill="#8AAAF0" d="M32 0L34 0 36 24 32 24z"></path>
                        <path fill="#8AAAF0" d="M18 0L20 0 22 24 18 24z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 792 90)">
                        <path fill="#D0DDF9" d="M0 24H36V48H0z"></path>
                        <path fill="#B9CCF6" d="M36 24H48V48H36z"></path>
                        <path fill="#8AAAF0" d="M48 24H60V36H48z"></path>
                        <path fill="#8AAAF0" d="M60 24H72V36H60z"></path>
                        <path fill="#8AAAF0" d="M72 24H84V36H72z"></path>
                        <path
                            fill="#E8EEFC"
                            d="M6 32a2 2 0 012 2v2H4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M60 24L60 48 48 48 48 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M72 24L72 48 60 48 60 36z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M84 24L84 48 72 48 72 36z"
                        ></path>
                        <path
                            fill="#E8EEFC"
                            d="M6 40a2 2 0 012 2v2H4v-2a2 2 0 012-2zM14 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2zM14 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2zM22 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2zM22 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2zM30 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2zM30 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M42 40a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#739AED"
                            d="M54 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AAAF0"
                            d="M66 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2zM78 40a2 2 0 012 2v6h-4v-6a2 2 0 012-2zM42 32a2 2 0 012 2v2h-4v-2a2 2 0 012-2z"
                        ></path>
                        <path fill="#B9CCF6" d="M30 0L32 0 32 24 28 24z"></path>
                        <path fill="#B9CCF6" d="M16 0L18 0 18 24 14 24z"></path>
                        <path fill="#8AAAF0" d="M32 0L34 0 36 24 32 24z"></path>
                        <path fill="#8AAAF0" d="M18 0L20 0 22 24 18 24z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 566 68)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <path fill="#E7EDFC" d="M0 32H20V36H0z"></path>
                        <path fill="#E7EDFC" d="M0 40H20V44H0z"></path>
                        <path fill="#E7EDFC" d="M0 48H20V52H0z"></path>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <path fill="#739AED" d="M20 32H36V36H20z"></path>
                        <path fill="#739AED" d="M20 40H36V44H20z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#av"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#av"
                            ></use>
                        </g>
                    </g>
                    <g transform="matrix(-1 0 0 1 456 88)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#au"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#au"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#at"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#at"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#as"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#as"
                            ></use>
                        </g>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <path fill="#739AED" d="M20 32H36V36H20z"></path>
                        <path fill="#739AED" d="M20 40H36V44H20z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ar"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ar"
                            ></use>
                        </g>
                    </g>
                    <g transform="translate(504 86)">
                        <path fill="#D0DDF9" d="M32 12H44V52H32z"></path>
                        <path fill="#B9CCF6" d="M44 12H56V52H44z"></path>
                        <path fill="#B9CCF6" d="M44 0L44 12 32 12z"></path>
                        <circle cx="38" cy="18" r="2" fill="#E7EDFC"></circle>
                        <circle cx="50" cy="18" r="2" fill="#8AABF0"></circle>
                        <path fill="#8AABF0" d="M44 0L56 12 44 12z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M0 32L10 24 20 32 20 52 0 52z"
                        ></path>
                        <path fill="#B9CCF6" d="M20 32H48V52H20z"></path>
                        <path
                            fill="#8AABF0"
                            d="M10 24L38 24 48 32 20 32z"
                        ></path>
                        <path
                            fill="#E7EDFC"
                            d="M10 40a2 2 0 012 2v10H8V42a2 2 0 012-2z"
                        ></path>
                        <path
                            fill="#8AABF0"
                            d="M26 36a2 2 0 012 2v10h-4V38a2 2 0 012-2zM34 36a2 2 0 012 2v10h-4V38a2 2 0 012-2zM42 36a2 2 0 012 2v10h-4V38a2 2 0 012-2z"
                        ></path>
                        <rect
                            width="4"
                            height="4"
                            x="8"
                            y="32"
                            fill="#E7EDFC"
                            rx="2"
                        ></rect>
                    </g>
                    <g transform="translate(264 60)">
                        <path fill="#B9CCF6" d="M0 32H20V88H0z"></path>
                        <path
                            fill="#739AED"
                            d="M20 20L40 20 20 32 0 32z"
                        ></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 32L40 20 40 32 40 88 20 88z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 36H8V84H4z"></path>
                        <path fill="#739AED" d="M24 36H28V40H24z"></path>
                        <path fill="#739AED" d="M24 44H28V48H24z"></path>
                        <path fill="#739AED" d="M24 52H28V56H24z"></path>
                        <path fill="#739AED" d="M24 60H28V64H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#aq"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#aq"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ao"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ao"
                            ></use>
                        </g>
                        <path fill="#E8EEFC" d="M12 36H16V84H12z"></path>
                        <path fill="#739AED" d="M32 36H36V40H32z"></path>
                        <path fill="#739AED" d="M32 44H36V48H32z"></path>
                        <path fill="#739AED" d="M32 52H36V56H32z"></path>
                        <path fill="#739AED" d="M32 60H36V64H32z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#an"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#an"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#am"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#am"
                            ></use>
                        </g>
                        <path fill="#B9CCF6" d="M20 0H40V20H20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M40 -3.1508e-13L60 -3.1508e-13 60 88 40 88z"
                        ></path>
                        <path fill="#E8EEFC" d="M24 4H28V20H24z"></path>
                        <path fill="#739AED" d="M44 4H48V8H44z"></path>
                        <path fill="#739AED" d="M44 12H48V16H44z"></path>
                        <path fill="#739AED" d="M44 20H48V24H44z"></path>
                        <path fill="#739AED" d="M44 28H48V32H44z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#al"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#al"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ak"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ak"
                            ></use>
                        </g>
                        <use fill="#1657E2" xlinkHref="#aj"></use>
                        <use
                            fill="#000"
                            fillOpacity="0.7"
                            xlinkHref="#aj"
                        ></use>
                        <use fill="#1657E2" xlinkHref="#ai"></use>
                        <use
                            fill="#000"
                            fillOpacity="0.7"
                            xlinkHref="#ai"
                        ></use>
                        <use fill="#1657E2" xlinkHref="#ah"></use>
                        <use
                            fill="#000"
                            fillOpacity="0.7"
                            xlinkHref="#ah"
                        ></use>
                        <use fill="#1657E2" xlinkHref="#ag"></use>
                        <use
                            fill="#000"
                            fillOpacity="0.7"
                            xlinkHref="#ag"
                        ></use>
                        <path fill="#E8EEFC" d="M32 4H36V20H32z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#af"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#af"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ad"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ad"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ac"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ac"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#ab"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#ab"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#aa"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#aa"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#z"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.7"
                                xlinkHref="#z"
                            ></use>
                        </g>
                        <use fill="#1657E2" xlinkHref="#y"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#y"></use>
                        <use fill="#1657E2" xlinkHref="#x"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#x"></use>
                        <use fill="#1657E2" xlinkHref="#w"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#w"></use>
                        <use fill="#1657E2" xlinkHref="#v"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#v"></use>
                    </g>
                    <g transform="translate(228 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(40 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(388)">
                        <path fill="#D0DDF9" d="M0 4H40V128H0z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M8 0H32V4H8z"
                            transform="matrix(1 0 0 -1 0 4)"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M32 0H48V4H32z"
                            transform="matrix(1 0 0 -1 0 4)"
                        ></path>
                        <path fill="#B9CCF6" d="M40 4H56V128H40z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V120H4z"></path>
                        <path fill="#E7EDFC" d="M16 12H24V120H16z"></path>
                        <path fill="#E7EDFC" d="M28 12H36V120H28z"></path>
                        <path fill="#B9CCF6" d="M4 12H12V40H4z"></path>
                        <path fill="#B9CCF6" d="M16 12H24V40H16z"></path>
                        <path fill="#B9CCF6" d="M28 12H36V40H28z"></path>
                        <path fill="#8AABF0" d="M44 12H52V120H44z"></path>
                    </g>
                    <g transform="translate(312 24)">
                        <path fill="#D0DDF9" d="M0 0H48V124H0z"></path>
                        <path fill="#B9CCF6" d="M48 0H68V124H48z"></path>
                        <path fill="#E7EDFC" d="M4 16H42V116H4z"></path>
                        <path fill="#fff" d="M4 44L4 32 42 56 42 68z"></path>
                        <path fill="#fff" d="M4 53L4 48 42 72 42 77z"></path>
                        <path fill="#8AABF0" d="M52 16H64V116H52z"></path>
                        <path fill="#8AABF0" d="M4 6H20V12H4z"></path>
                    </g>
                    <g transform="translate(408 64)">
                        <path
                            fill="#B8CCF6"
                            d="M0 1.0805e-13L28 1.0805e-13 28 68 0 68z"
                        ></path>
                        <path fill="#A1BBF3" d="M28 0H40V68H28z"></path>
                        <path fill="#E8EEFC" d="M4 8H24V12H4z"></path>
                        <path fill="#E8EEFC" d="M4 16H8V56H4z"></path>
                        <path fill="#E8EEFC" d="M20 16H24V56H20z"></path>
                        <path fill="#E8EEFC" d="M12 16H16V56H12z"></path>
                        <path fill="#739AED" d="M32 8H36V12H32z"></path>
                        <path fill="#739AED" d="M32 16H36V48H32z"></path>
                    </g>
                    <g transform="matrix(-1 0 0 1 504 72)">
                        <path fill="#B8CCF6" d="M0 20H20V76H0z"></path>
                        <path fill="#739AED" d="M20 0L40 0 20 20 0 20z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 20L40 0 40 20 40 76 20 76z"
                        ></path>
                        <path fill="#E8EEFC" d="M4 24H8V28H4z"></path>
                        <path fill="#739AED" d="M24 24H28V28H24z"></path>
                        <path fill="#E8EEFC" d="M4 32H8V36H4z"></path>
                        <path fill="#739AED" d="M24 32H28V36H24z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#739AED" d="M24 40H28V44H24z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#739AED" d="M24 48H28V52H24z"></path>
                        <path fill="#E8EEFC" d="M4 56H8V60H4z"></path>
                        <path fill="#739AED" d="M24 56H28V60H24z"></path>
                        <path fill="#E8EEFC" d="M4 64H8V68H4z"></path>
                        <path fill="#739AED" d="M24 64H28V68H24z"></path>
                        <path fill="#E8EEFC" d="M12 24H16V28H12z"></path>
                        <path fill="#739AED" d="M32 24H36V28H32z"></path>
                        <path fill="#E8EEFC" d="M12 32H16V36H12z"></path>
                        <path fill="#739AED" d="M32 32H36V36H32z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#739AED" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#739AED" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M12 56H16V60H12z"></path>
                        <path fill="#739AED" d="M32 56H36V60H32z"></path>
                        <path fill="#E8EEFC" d="M12 64H16V68H12z"></path>
                        <path fill="#091E43" d="M32 64H36V68H32z"></path>
                    </g>
                    <g transform="translate(328 72)">
                        <path fill="#B8CCF6" d="M0 0H20V56H0z"></path>
                        <path fill="#E7EDFC" d="M0 8H20V12H0z"></path>
                        <path fill="#E7EDFC" d="M0 16H20V20H0z"></path>
                        <path fill="#E7EDFC" d="M0 24H20V28H0z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#u"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#u"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#s"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#s"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#r"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#r"
                            ></use>
                        </g>
                        <path fill="#A1BBF3" d="M20 0H36V56H20z"></path>
                        <path fill="#739AED" d="M20 8H36V12H20z"></path>
                        <path fill="#739AED" d="M20 16H36V20H20z"></path>
                        <path fill="#739AED" d="M20 24H36V28H20z"></path>
                        <use fill="#1657E2" xlinkHref="#q"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#q"></use>
                        <use fill="#1657E2" xlinkHref="#p"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#p"></use>
                        <use fill="#1657E2" xlinkHref="#o"></use>
                        <use fill="#000" fillOpacity="0.7" xlinkHref="#o"></use>
                    </g>
                    <g transform="translate(288 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#n"></use>
                            <use
                                fill="#000"
                                fillOpacity="0.5"
                                xlinkHref="#n"
                            ></use>
                        </g>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#m"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#m"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#l"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#l"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#k"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#k"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#j"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#j"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#h"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#h"
                            ></use>
                        </g>
                        <g>
                            <use fill="#1657E2" xlinkHref="#g"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#g"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(92.5 92)">
                        <path fill="#A1BBF3" d="M32 0H44V36H32z"></path>
                        <path fill="#A1BBF3" d="M56 8H68V36H56z"></path>
                        <path fill="#A1BBF3" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#B8CCF6" d="M12 0H32V36H12z"></path>
                        <path fill="#B8CCF6" d="M0 36H20V56H0z"></path>
                        <path fill="#B8CCF6" d="M28 36H48V56H28z"></path>
                        <path fill="#B8CCF6" d="M36 8H56V36H36z"></path>
                        <path fill="#E8EEFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E8EEFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E8EEFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E8EEFC" d="M16 20H20V24H16z"></path>
                        <path fill="#E8EEFC" d="M4 40H8V44H4z"></path>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E8EEFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E8EEFC" d="M16 28H20V32H16z"></path>
                        <path fill="#E8EEFC" d="M4 48H8V52H4z"></path>
                        <path fill="#E8EEFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E8EEFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E8EEFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E8EEFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M48 12H52V16H48z"></path>
                        <path fill="#739AED" d="M60 12H64V16H60z"></path>
                        <path fill="#E8EEFC" d="M24 20H28V24H24z"></path>
                        <path fill="#E8EEFC" d="M12 40H16V44H12z"></path>
                        <path fill="#E8EEFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E8EEFC" d="M48 20H52V24H48z"></path>
                        <path fill="#739AED" d="M60 20H64V24H60z"></path>
                        <path fill="#E8EEFC" d="M24 28H28V32H24z"></path>
                        <path fill="#E8EEFC" d="M12 48H16V52H12z"></path>
                        <path fill="#E8EEFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E8EEFC" d="M48 28H52V32H48z"></path>
                        <path fill="#739AED" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(344 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#f"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#f"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#e"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#e"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#d"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#d"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <g>
                            <use fill="#1657E2" xlinkHref="#c"></use>
                            <use
                                fill="#FFF"
                                fillOpacity="0.8"
                                xlinkHref="#c"
                            ></use>
                        </g>
                        <path fill="#E7EDFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(600 92)">
                        <path fill="#B9CCF6" d="M32 0H44V36H32z"></path>
                        <path fill="#B9CCF6" d="M56 8H68V36H56z"></path>
                        <path fill="#B9CCF6" d="M12 36H32V56H12z"></path>
                        <path fill="#B9CCF6" d="M48 36H68V56H48z"></path>
                        <path fill="#D0DDF9" d="M12 0H32V36H12z"></path>
                        <path fill="#D0DDF9" d="M0 36H20V56H0z"></path>
                        <path fill="#D0DDF9" d="M28 36H48V56H28z"></path>
                        <path fill="#D0DDF9" d="M36 8H56V36H36z"></path>
                        <path fill="#E7EDFC" d="M16 4H20V8H16z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V16H16z"></path>
                        <path fill="#E7EDFC" d="M40 12H44V16H40z"></path>
                        <path fill="#E7EDFC" d="M16 20H20V24H16z"></path>
                        <path fill="#E7EDFC" d="M4 40H8V44H4z"></path>
                        <path fill="#E7EDFC" d="M32 40H36V44H32z"></path>
                        <path fill="#E7EDFC" d="M40 20H44V24H40z"></path>
                        <path fill="#E7EDFC" d="M16 28H20V32H16z"></path>
                        <path fill="#E7EDFC" d="M4 48H8V52H4z"></path>
                        <path fill="#E7EDFC" d="M32 48H36V52H32z"></path>
                        <path fill="#E7EDFC" d="M40 28H44V32H40z"></path>
                        <path fill="#E7EDFC" d="M24 4H28V8H24z"></path>
                        <path fill="#E7EDFC" d="M24 12H28V16H24z"></path>
                        <path fill="#E7EDFC" d="M48 12H52V16H48z"></path>
                        <path fill="#8AABF0" d="M60 12H64V16H60z"></path>
                        <path fill="#E7EDFC" d="M24 20H28V24H24z"></path>
                        <path fill="#E7EDFC" d="M12 40H16V44H12z"></path>
                        <path fill="#E7EDFC" d="M40 40H44V44H40z"></path>
                        <path fill="#E7EDFC" d="M48 20H52V24H48z"></path>
                        <path fill="#8AABF0" d="M60 20H64V24H60z"></path>
                        <path fill="#E7EDFC" d="M24 28H28V32H24z"></path>
                        <path fill="#E7EDFC" d="M12 48H16V52H12z"></path>
                        <path fill="#E7EDFC" d="M40 48H44V52H40z"></path>
                        <path fill="#E7EDFC" d="M48 28H52V32H48z"></path>
                        <path fill="#8AABF0" d="M60 28H64V32H60z"></path>
                    </g>
                    <g transform="translate(276 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(140 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(432 112)">
                        <path fill="#B9CCF6" d="M28 0H40V36H28z"></path>
                        <path fill="#D0DDF9" d="M0 0H28V36H0z"></path>
                        <path fill="#E7EDFC" d="M4 4H8V8H4z"></path>
                        <path fill="#E7EDFC" d="M4 12H8V16H4z"></path>
                        <path fill="#E7EDFC" d="M4 20H8V24H4z"></path>
                        <use fill="#1657E2" xlinkHref="#b"></use>
                        <use fill="#FFF" fillOpacity="0.8" xlinkHref="#b"></use>
                        <path fill="#E7EDFC" d="M12 4H16V8H12z"></path>
                        <path fill="#E7EDFC" d="M20 4H24V8H20z"></path>
                        <path fill="#8AABF0" d="M32 4H36V8H32z"></path>
                        <path fill="#E7EDFC" d="M12 12H16V16H12z"></path>
                        <path fill="#E7EDFC" d="M20 12H24V16H20z"></path>
                        <path fill="#8AABF0" d="M32 12H36V16H32z"></path>
                        <path fill="#8AABF0" d="M32 20H36V24H32z"></path>
                        <path fill="#E7EDFC" d="M12 20H16V24H12z"></path>
                        <path fill="#E7EDFC" d="M20 20H24V24H20z"></path>
                        <path fill="#E7EDFC" d="M12 28H16V32H12z"></path>
                        <path fill="#E7EDFC" d="M20 28H24V32H20z"></path>
                    </g>
                    <g transform="translate(328 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#B8CCF6" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#D0DDF9"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(400 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(464 118)">
                        <path fill="#D0DDF9" d="M0 2H28V30H0z"></path>
                        <path fill="#B9CCF6" d="M28 2H40V30H28z"></path>
                        <path fill="#8AAAF0" d="M0 0H28V2H0z"></path>
                        <path fill="#8AAAF0" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#8AAAF0" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#8AAAF0" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#8AAAF0" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#8AAAF0" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(496 128)">
                        <path fill="#B8CCF6" d="M0 8H44V20H0z"></path>
                        <path fill="#739AED" d="M6 0L50 0 44 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M44 8L50 0 56 8 56 20 44 20z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 12H28V20H24z"></path>
                        <path fill="#E7EDFC" d="M32 12H40V16H32z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V16H4z"></path>
                        <path fill="#739AED" d="M48 12H52V16H48z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V20H16z"></path>
                    </g>
                    <g transform="translate(736 128)">
                        <path fill="#B8CCF6" d="M0 8H44V20H0z"></path>
                        <path fill="#739AED" d="M6 0L50 0 44 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M44 8L50 0 56 8 56 20 44 20z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 12H28V20H24z"></path>
                        <path fill="#E7EDFC" d="M32 12H40V16H32z"></path>
                        <path fill="#E7EDFC" d="M4 12H12V16H4z"></path>
                        <path fill="#739AED" d="M48 12H52V16H48z"></path>
                        <path fill="#E7EDFC" d="M16 12H20V20H16z"></path>
                    </g>
                    <g transform="translate(0 127)">
                        <path fill="#D0DDF9" d="M0 9H44V21H0z"></path>
                        <path fill="#8AAAF0" d="M6 1L50 1 44 9 0 9z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M44 9L50 1 56 9 56 21 44 21z"
                        ></path>
                        <path fill="#E7EDFC" d="M24 13H28V21H24z"></path>
                        <path fill="#E7EDFC" d="M32 13H40V17H32z"></path>
                        <path fill="#E7EDFC" d="M4 13H12V17H4z"></path>
                        <path fill="#8AAAF0" d="M48 13H52V17H48z"></path>
                        <path fill="#E7EDFC" d="M16 13H20V21H16z"></path>
                        <path fill="#D0DDF9" d="M8 0L11 0 11 5 8 5z"></path>
                        <path fill="#D0DDF9" d="M36 0L39 0 39 5 36 5z"></path>
                        <path
                            fill="#B9CCF6"
                            d="M11 -2.2898e-15L12.5 -2.2898e-15 12.5 2.9957 11 5z"
                        ></path>
                        <path
                            fill="#B9CCF6"
                            d="M39 -2.2898e-15L40.5 -2.2898e-15 40.5 2.9957 39 5z"
                        ></path>
                    </g>
                    <g transform="translate(68 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(548 110)">
                        <path fill="#D0DDF9" d="M0 26H36V38H0z"></path>
                        <path fill="#8AABF0" d="M16 30H20V38H16z"></path>
                        <path fill="#E8EEFC" d="M4 30H12V34H4z"></path>
                        <path fill="#E8EEFC" d="M24 30H32V34H24z"></path>
                        <path fill="#D0DDF9" d="M8 14H44V26H8z"></path>
                        <path fill="#D0DDF9" d="M16 2H52V14H16z"></path>
                        <path fill="#B9CCF6" d="M52 2H64V14H52z"></path>
                        <path fill="#B9CCF6" d="M44 14H64V26H44z"></path>
                        <path fill="#B9CCF6" d="M36 26H64V38H36z"></path>
                        <path fill="#E8EEFC" d="M12 18H16V22H12z"></path>
                        <path fill="#E8EEFC" d="M20 18H24V22H20z"></path>
                        <path fill="#E8EEFC" d="M28 18H32V22H28z"></path>
                        <path fill="#E8EEFC" d="M36 18H40V22H36z"></path>
                        <path fill="#E8EEFC" d="M20 6H24V10H20z"></path>
                        <path fill="#E8EEFC" d="M28 6H32V10H28z"></path>
                        <path fill="#E8EEFC" d="M36 6H40V10H36z"></path>
                        <path fill="#E8EEFC" d="M44 6H48V10H44z"></path>
                        <path fill="#8AABF0" d="M56 6H60V10H56z"></path>
                        <path fill="#8AABF0" d="M56 18H60V22H56z"></path>
                        <path fill="#091E43" d="M56 30H60V34H56z"></path>
                        <path fill="#8AABF0" d="M48 18H52V22H48z"></path>
                        <path fill="#8AABF0" d="M48 30H52V34H48z"></path>
                        <path fill="#8AABF0" d="M40 30H44V34H40z"></path>
                        <path fill="#8AABF0" d="M16 0H52V2H16z"></path>
                        <path fill="#8AABF0" d="M52 0H64V2H52z"></path>
                    </g>
                    <g transform="translate(660 118)">
                        <path fill="#B8CCF6" d="M0 2H28V30H0z"></path>
                        <path fill="#A1BBF3" d="M28 2H40V30H28z"></path>
                        <path fill="#739AED" d="M0 0H28V2H0z"></path>
                        <path fill="#739AED" d="M28 0H40V2H28z"></path>
                        <path fill="#E7EDFC" d="M4 22H8V26H4z"></path>
                        <path fill="#E7EDFC" d="M4 14H8V18H4z"></path>
                        <path fill="#E7EDFC" d="M12 14H16V18H12z"></path>
                        <path fill="#E7EDFC" d="M4 6H8V10H4z"></path>
                        <path fill="#E7EDFC" d="M12 6H16V10H12z"></path>
                        <path fill="#739AED" d="M12 22H16V30H12z"></path>
                        <path fill="#E7EDFC" d="M20 22H24V26H20z"></path>
                        <path fill="#739AED" d="M32 22H36V26H32z"></path>
                        <path fill="#E7EDFC" d="M20 14H24V18H20z"></path>
                        <path fill="#739AED" d="M32 14H36V18H32z"></path>
                        <path fill="#E7EDFC" d="M20 6H24V10H20z"></path>
                        <path fill="#739AED" d="M32 6H36V10H32z"></path>
                    </g>
                    <g transform="translate(788 128)">
                        <path fill="#B8CCF6" d="M0 8H20V20H0z"></path>
                        <path fill="#739AED" d="M6 0L26 0 20 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 8L26 0 32 8 32 20 20 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M12 12H16V16H12z"></path>
                        <path fill="#739AED" d="M24 12H28V16H24z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V20H4z"></path>
                    </g>
                    <g transform="translate(604 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                    <g transform="translate(696 128)">
                        <path fill="#B8CCF6" d="M0 8H28V20H0z"></path>
                        <path fill="#739AED" d="M6 0L34 0 28 8 0 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M28 8L34 0 40 8 40 20 28 20z"
                        ></path>
                        <path fill="#E8EEFC" d="M20 12H24V16H20z"></path>
                        <path fill="#E8EEFC" d="M4 12H8V16H4z"></path>
                        <path fill="#739AED" d="M32 12H36V16H32z"></path>
                        <path fill="#E8EEFC" d="M12 12H16V20H12z"></path>
                        <path fill="#C5D5F7" d="M14 2L20 8 8 8z"></path>
                        <path
                            fill="#A1BBF3"
                            d="M20 2L26 8 14 8z"
                            transform="rotate(180 20 5)"
                        ></path>
                    </g>
                </g>
                <g transform="translate(85 -607) translate(-85 607) translate(444 28)">
                    <path
                        fill="#0842C0"
                        d="M727.83 81.6c8.642 17.067 11.842 29.867 9.6 38.4h-19.2c-2.106-8.533 1.094-21.333 9.6-38.4z"
                    ></path>
                    <g transform="translate(36.8 20.8) translate(153.6 67.2)">
                        <path fill="#fff" d="M0 12.8H32V32H0z"></path>
                        <path
                            fill="#091E43"
                            d="M9.6 0L41.6 0 32 12.8 0 12.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 12.8L41.6 0 51.2 12.8 51.2 32 32 32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 19.2H25.6V25.6H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M6.4 19.2H12.8V32H6.4z"></path>
                        <path fill="#fff" d="M35.2 12.8H67.2V32H35.2z"></path>
                        <path
                            fill="#091E43"
                            d="M44.8 0L76.8 0 67.2 12.8 35.2 12.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M67.2 12.8L76.8 0 86.4 12.8 86.4 32 67.2 32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M54.4 19.2H60.8V25.6H54.4z"
                        ></path>
                        <path fill="#C3D0D8" d="M41.6 19.2H48V32H41.6z"></path>
                        <path fill="#fff" d="M70.4 12.8H102.4V32H70.4z"></path>
                        <path
                            fill="#091E43"
                            d="M80 0L112 0 102.4 12.8 70.4 12.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M102.4 12.8L112 0 121.6 12.8 121.6 32 102.4 32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M89.6 19.2H96V25.6H89.6z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M108.8 19.2H115.2V25.6H108.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M76.8 19.2H83.2V32H76.8z"
                        ></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(268.8)">
                        <path fill="#fff" d="M0 3.2H44.8V99.2H0z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 3.2H76.8V99.2H44.8z"
                        ></path>
                        <path fill="#091E43" d="M0 0H44.8V3.2H0z"></path>
                        <path fill="#031432" d="M44.8 0H76.8V3.2H44.8z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 86.4H12.8V92.80000000000001H6.4z"
                        ></path>
                        <path fill="#C3D0D8" d="M6.4 48H12.8V54.4H6.4z"></path>
                        <path fill="#C3D0D8" d="M6.4 73.6H12.8V80H6.4z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 35.2H12.8V41.6H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 73.6H25.6V80H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 35.2H25.6V41.6H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 60.8H12.8V67.2H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 60.8H25.6V67.2H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 48H25.6V54.4H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M19.2 9.6H25.6V16H19.2z"></path>
                        <path fill="#C3D0D8" d="M32 9.6H38.4V16H32z"></path>
                        <path fill="#C3D0D8" d="M6.4 9.6H12.8V16H6.4z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V28.799999999999997H19.2z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M19.2 86.4H25.6V99.2H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 86.4H38.4V92.80000000000001H32z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 48H38.4V54.4H32z"></path>
                        <path
                            fill="#091E43"
                            d="M51.2 35.2H57.6V41.6H51.2z"
                        ></path>
                        <path fill="#091E43" d="M64 35.2H70.4V41.6H64z"></path>
                        <path fill="#C3D0D8" d="M32 73.6H38.4V80H32z"></path>
                        <path fill="#C3D0D8" d="M32 35.2H38.4V41.6H32z"></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M64 22.4H70.4V28.799999999999997H64z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 60.8H38.4V67.2H32z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path fill="#091E43" d="M51.2 9.6H57.6V16H51.2z"></path>
                        <path fill="#091E43" d="M64 9.6H70.4V16H64z"></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(460.8)">
                        <path fill="#fff" d="M0 3.2H44.8V99.2H0z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 3.2H76.8V99.2H44.8z"
                        ></path>
                        <path fill="#091E43" d="M0 0H44.8V3.2H0z"></path>
                        <path fill="#031432" d="M44.8 0H76.8V3.2H44.8z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 86.4H12.8V92.80000000000001H6.4z"
                        ></path>
                        <path fill="#C3D0D8" d="M6.4 48H12.8V54.4H6.4z"></path>
                        <path fill="#C3D0D8" d="M6.4 73.6H12.8V80H6.4z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 35.2H12.8V41.6H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 73.6H25.6V80H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 35.2H25.6V41.6H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 60.8H12.8V67.2H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 60.8H25.6V67.2H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 48H25.6V54.4H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M19.2 9.6H25.6V16H19.2z"></path>
                        <path fill="#C3D0D8" d="M32 9.6H38.4V16H32z"></path>
                        <path fill="#C3D0D8" d="M6.4 9.6H12.8V16H6.4z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V28.799999999999997H19.2z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M19.2 86.4H25.6V99.2H19.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 86.4H38.4V92.80000000000001H32z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 48H38.4V54.4H32z"></path>
                        <path
                            fill="#091E43"
                            d="M51.2 35.2H57.6V41.6H51.2z"
                        ></path>
                        <path fill="#091E43" d="M64 35.2H70.4V41.6H64z"></path>
                        <path fill="#C3D0D8" d="M32 73.6H38.4V80H32z"></path>
                        <path fill="#C3D0D8" d="M32 35.2H38.4V41.6H32z"></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M64 22.4H70.4V28.799999999999997H64z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 60.8H38.4V67.2H32z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path fill="#091E43" d="M51.2 9.6H57.6V16H51.2z"></path>
                        <path fill="#091E43" d="M64 9.6H70.4V16H64z"></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(320 51.2)">
                        <path fill="#fff" d="M0 3.2H44.8V48H0z"></path>
                        <path fill="#C3D0D8" d="M44.8 3.2H64V48H44.8z"></path>
                        <path fill="#091E43" d="M0 0H44.8V3.2H0z"></path>
                        <path fill="#031432" d="M44.8 0H64V3.2H44.8z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 35.2H12.8V41.6H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V28.799999999999997H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M6.4 9.6H12.8V16H6.4z"></path>
                        <path fill="#C3D0D8" d="M19.2 9.6H25.6V16H19.2z"></path>
                        <path
                            fill="#091E43"
                            d="M19.2 35.2H25.6V48H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 35.2H38.4V41.6H32z"></path>
                        <path
                            fill="#091E43"
                            d="M51.2 35.2H57.6V41.6H51.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 9.6H38.4V16H32z"></path>
                        <path fill="#091E43" d="M51.2 9.6H57.6V16H51.2z"></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(371.2 64)">
                        <path fill="#fff" d="M4 0H10.4V12H4z"></path>
                        <path fill="#C3D0D8" d="M7.2 0H12V7.2H7.2z"></path>
                        <path fill="#fff" d="M0 16H44.8V35.2H0z"></path>
                        <path
                            fill="#091E43"
                            d="M9.6 3.2L54.4 3.2 44.8 16 0 16z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 16L54.4 3.2 64 16 64 35.2 44.8 35.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V35.2H19.2z"
                        ></path>
                        <path fill="#fff" d="M22.4 6.4L32 16 12.8 16z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 6.4L41.6 16 22.4 16z"
                            transform="rotate(180 32 11.2)"
                        ></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(512 64)">
                        <path fill="#fff" d="M4 0H10.4V12H4z"></path>
                        <path fill="#C3D0D8" d="M7.2 0H12V7.2H7.2z"></path>
                        <path fill="#fff" d="M0 16H44.8V35.2H0z"></path>
                        <path
                            fill="#091E43"
                            d="M9.6 3.2L54.4 3.2 44.8 16 0 16z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 16L54.4 3.2 64 16 64 35.2 44.8 35.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V35.2H19.2z"
                        ></path>
                        <path fill="#fff" d="M22.4 6.4L32 16 12.8 16z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 6.4L41.6 16 22.4 16z"
                            transform="rotate(180 32 11.2)"
                        ></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(582.4 64)">
                        <path fill="#fff" d="M4 0H10.4V12H4z"></path>
                        <path fill="#C3D0D8" d="M7.2 0H12V7.2H7.2z"></path>
                        <path fill="#fff" d="M0 16H44.8V35.2H0z"></path>
                        <path
                            fill="#091E43"
                            d="M9.6 3.2L54.4 3.2 44.8 16 0 16z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 16L54.4 3.2 64 16 64 35.2 44.8 35.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 22.4H38.4V28.799999999999997H32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 22.4H12.8V28.799999999999997H6.4z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M51.2 22.4H57.6V28.799999999999997H51.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 22.4H25.6V35.2H19.2z"
                        ></path>
                        <path fill="#fff" d="M22.4 6.4L32 16 12.8 16z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 6.4L41.6 16 22.4 16z"
                            transform="rotate(180 32 11.2)"
                        ></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(640 67.2)">
                        <path fill="#fff" d="M0 12.8H32V32H0z"></path>
                        <path
                            fill="#091E43"
                            d="M9.6 0L41.6 0 32 12.8 0 12.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M32 12.8L41.6 0 51.2 12.8 51.2 32 32 32z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 19.2H25.6V25.6H19.2z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M38.4 19.2H44.8V25.6H38.4z"
                        ></path>
                        <path fill="#C3D0D8" d="M6.4 19.2H12.8V32H6.4z"></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(44.8 38.4)">
                        <path fill="#fff" d="M0 41.6H57.6V60.8H0z"></path>
                        <path fill="#091E43" d="M25.6 48H32V60.8H25.6z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M6.4 48H19.200000000000003V54.4H6.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M38.4 48H51.2V54.4H38.4z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M12.8 22.4H70.4V41.599999999999994H12.8z"
                        ></path>
                        <path fill="#fff" d="M25.6 3.2H83.2V22.4H25.6z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M83.2 3.2H102.4V22.4H83.2z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M70.4 22.4H102.4V41.599999999999994H70.4z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M57.6 41.6H102.4V60.8H57.6z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M19.2 28.8H25.6V35.2H19.2z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 28.8H38.4V35.2H32z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 28.8H51.199999999999996V35.2H44.8z"
                        ></path>
                        <path
                            fill="#C3D0D8"
                            d="M57.6 28.8H64V35.2H57.6z"
                        ></path>
                        <path fill="#C3D0D8" d="M32 9.6H38.4V16H32z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M44.8 9.6H51.199999999999996V16H44.8z"
                        ></path>
                        <path fill="#C3D0D8" d="M57.6 9.6H64V16H57.6z"></path>
                        <path
                            fill="#C3D0D8"
                            d="M70.4 9.6H76.80000000000001V16H70.4z"
                        ></path>
                        <path fill="#091E43" d="M89.6 9.6H96V16H89.6z"></path>
                        <path
                            fill="#091E43"
                            d="M89.6 28.8H96V35.2H89.6z"
                        ></path>
                        <path fill="#091E43" d="M89.6 48H96V54.4H89.6z"></path>
                        <path
                            fill="#091E43"
                            d="M76.8 28.8H83.2V35.2H76.8z"
                        ></path>
                        <path
                            fill="#091E43"
                            d="M76.8 48H83.2V54.4H76.8z"
                        ></path>
                        <path fill="#091E43" d="M64 48H70.4V54.4H64z"></path>
                        <path fill="#091E43" d="M25.6 0H83.2V3.2H25.6z"></path>
                        <path fill="#031432" d="M83.2 0H102.4V3.2H83.2z"></path>
                    </g>
                    <g transform="translate(36.8 20.8) translate(0 83.2)">
                        <circle
                            cx="17.6"
                            cy="12.8"
                            r="3.2"
                            fill="#0B358D"
                        ></circle>
                        <circle
                            cx="36.8"
                            cy="12.8"
                            r="3.2"
                            fill="#0B358D"
                        ></circle>
                        <path
                            fill="#779DED"
                            d="M0 6.4h41.6v6.4H6.4A6.4 6.4 0 010 6.4z"
                        ></path>
                        <path
                            fill="#1657E2"
                            d="M17.6 0h13.6a6.4 6.4 0 016.4 6.4h-20V0z"
                        ></path>
                        <path
                            fill="#779DED"
                            d="M12.8 0H20a6.4 6.4 0 016.4 6.4h-20A6.4 6.4 0 0112.8 0z"
                        ></path>
                        <path
                            fill="#1657E2"
                            d="M32 6.4h19.2a6.4 6.4 0 01-6.4 6.4h-6.4A6.4 6.4 0 0132 6.4z"
                        ></path>
                        <circle
                            cx="37.6"
                            cy="9.6"
                            r="1.6"
                            fill="#F0F0F0"
                        ></circle>
                        <circle
                            cx="46.4"
                            cy="9.6"
                            r="1.6"
                            fill="#F0F0F0"
                        ></circle>
                        <circle cx="9.6" cy="12.8" r="3.2" fill="#000"></circle>
                        <circle
                            cx="28.8"
                            cy="12.8"
                            r="3.2"
                            fill="#000"
                        ></circle>
                    </g>
                    <circle
                        cx="473.6"
                        cy="19.2"
                        r="9.6"
                        fill="#F7B633"
                    ></circle>
                    <path
                        fill="#fff"
                        d="M28.8 52A6.4 6.4 0 0018 47.352 9.595 9.595 0 009.6 42.4c-5.22 0-9.468 4.167-9.597 9.356L0 52h28.8zM260.8 9.6A6.4 6.4 0 00250 4.952 9.595 9.595 0 00241.6 0c-5.22 0-9.467 4.167-9.597 9.356L232 9.6h28.8zM450.4 52a6.4 6.4 0 00-10.8-4.648 9.595 9.595 0 00-8.4-4.952c-5.22 0-9.467 4.167-9.597 9.356L421.6 52h28.8zM246.4 69.6a6.4 6.4 0 00-10.8-4.648A9.595 9.595 0 00227.2 60c-5.22 0-9.467 4.167-9.597 9.356l-.003.244h28.8zM107.2 24a6.4 6.4 0 0110.8-4.648 9.595 9.595 0 018.4-4.952c5.22 0 9.467 4.167 9.597 9.356L136 24h-28.8zM636 64.8a6.4 6.4 0 0110.8-4.648 9.595 9.595 0 018.4-4.952c5.22 0 9.467 4.167 9.597 9.356l.003.244H636zM724 14.4a6.4 6.4 0 00-10.8-4.648 9.595 9.595 0 00-8.4-4.952c-5.22 0-9.467 4.167-9.597 9.356l-.003.244H724z"
                    ></path>
                    <path
                        fill="#0842C0"
                        d="M600.63 81.6c8.642 17.067 11.842 29.867 9.6 38.4h-19.2c-2.106-8.533 1.094-21.333 9.6-38.4z"
                    ></path>
                    <g transform="translate(164 94.4)">
                        <rect
                            width="12.8"
                            height="19.2"
                            fill="#779DED"
                            rx="6.4"
                        ></rect>
                        <rect
                            width="1.6"
                            height="16"
                            x="5.6"
                            y="9.6"
                            fill="#091E43"
                            rx="0.8"
                        ></rect>
                    </g>
                    <g transform="translate(605.6 94.4)">
                        <rect
                            width="12.8"
                            height="19.2"
                            fill="#779DED"
                            rx="6.4"
                        ></rect>
                        <rect
                            width="1.6"
                            height="16"
                            x="5.6"
                            y="9.6"
                            fill="#091E43"
                            rx="0.8"
                        ></rect>
                    </g>
                    <g transform="translate(476.8 94.4)">
                        <rect
                            width="12.8"
                            height="19.2"
                            fill="#779DED"
                            rx="6.4"
                        ></rect>
                        <rect
                            width="1.6"
                            height="16"
                            x="5.6"
                            y="9.6"
                            fill="#091E43"
                            rx="0.8"
                        ></rect>
                    </g>
                    <g transform="translate(398.4 113.6)">
                        <path
                            fill="#1657E2"
                            d="M0 0l.2.003a6.4 6.4 0 016.197 6.198L6.4 6.4l-.2-.003A6.4 6.4 0 01.004.199L0 0z"
                        ></path>
                        <path
                            fill="#779DED"
                            d="M12.8 0l-.003.2a6.4 6.4 0 01-5.996 6.188l-.202.009L6.4 6.4l.003-.2A6.4 6.4 0 0112.4.013L12.6.003 12.8 0z"
                        ></path>
                    </g>
                    <g transform="translate(658.4 113.6)">
                        <path
                            fill="#1657E2"
                            d="M0 0l.2.003a6.4 6.4 0 016.197 6.198L6.4 6.4l-.2-.003A6.4 6.4 0 01.004.199L0 0z"
                        ></path>
                        <path
                            fill="#779DED"
                            d="M12.8 0l-.003.2a6.4 6.4 0 01-5.996 6.188l-.202.009L6.4 6.4l.003-.2A6.4 6.4 0 0112.4.013L12.6.003 12.8 0z"
                        ></path>
                    </g>
                    <path
                        fill="#1657E2"
                        d="M254.4 113.6l.2.003a6.4 6.4 0 016.197 6.198l.003.199-.2-.003a6.4 6.4 0 01-6.197-6.198l-.003-.199zm12.8 0l-.003.2a6.4 6.4 0 01-5.996 6.188l-.202.009-.199.003.003-.2a6.4 6.4 0 015.996-6.188l.202-.009.199-.003zM132.8 113.6l.2.003a6.4 6.4 0 016.197 6.198l.003.199-.2-.003a6.4 6.4 0 01-6.197-6.198l-.003-.199zm12.8 0l-.003.2a6.4 6.4 0 01-5.996 6.188l-.202.009-.199.003.003-.2a6.4 6.4 0 015.996-6.188l.202-.009.199-.003zM586.4 120a6.4 6.4 0 0110.8-4.648 9.595 9.595 0 018.4-4.952c5.22 0 9.467 4.167 9.597 9.356l.003.244h-28.8zM167.2 120a6.4 6.4 0 0110.8-4.648 9.595 9.595 0 018.4-4.952c5.22 0 9.467 4.167 9.597 9.356L196 120h-28.8zM454.4 120a6.4 6.4 0 0110.8-4.648 9.595 9.595 0 018.4-4.952c5.22 0 9.467 4.167 9.597 9.356l.003.244h-28.8zM752.8 120a6.4 6.4 0 00-10.8-4.648 9.595 9.595 0 00-8.4-4.952c-5.22 0-9.468 4.167-9.597 9.356L724 120h28.8z"
                    ></path>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationCity
