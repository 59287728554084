import React from "react"
import { graphql } from "gatsby"
import "./styles.scss"

import Layout from "../components/layout"
import Header from "../components/header"
import About from "../components/sections/about"
import {
    StyledSection,
    StyledContainer,
    StyledOuterWrapper,
} from "../styles/global"
import ServiceAreas from "../components/sections/serviceAreas"
import Contact from "../components/sections/contact"
import { ILocation } from "./../interface/location"
import Hero from "../components/hero"
//import News from "../components/sections/news"

const IndexPage: React.FC<{ location: ILocation; data: any }> = ({
    location,
    data,
}: any) => (
    <Layout location={location}>
        <Header data={data.forside.edges[0].node.frontmatter} />
        <StyledOuterWrapper frontpage={false}>
            <StyledSection>
                <StyledContainer frontpage>
                    <About edges={data.about.edges[0]} />
                </StyledContainer>
            </StyledSection>
        </StyledOuterWrapper>

        <StyledOuterWrapper frontpage={false}>
            <StyledSection>
                <StyledContainer frontpage>
                    <ServiceAreas data={data.tjenester.edges[0]} />
                </StyledContainer>
            </StyledSection>
        </StyledOuterWrapper>
        <StyledContainer fullwidth>
            <Hero
                heading={data.contact.edges[0].node.frontmatter.subheadingJobb}
                text={data.contact.edges[0].node.frontmatter.contentJobb}
            ></Hero>
        </StyledContainer>

        {/*<StyledOuterWrapper frontpage={false}>
            <StyledSection>
                <StyledContainer frontpage>
                    <News edges={data.about.edges[0]} />
                </StyledContainer>
            </StyledSection>
        </StyledOuterWrapper>*/}
        
        <StyledOuterWrapper frontpage={false}>
            <StyledSection>
                <StyledContainer frontpage>
                    <Contact data={data.contact.edges[0]} />
                </StyledContainer>
            </StyledSection>
        </StyledOuterWrapper>
    </Layout>
)

export default IndexPage

export const data = graphql`
    query AllDataQuery {
        title: site {
            siteMetadata {
                title
            }
        }
        forside: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/CMSContent/Frontpage/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        slogan
                        ingress
                    }
                }
            }
        }
        about: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/CMSContent/About/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        heading_ab_two
                        paragraph_ab_two
                        paragraph_ab_three
                        picture_ab_two {
                            childImageSharp {
                                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                            }
                        }
                        picture_ab_three {
                            childImageSharp {
                                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
        tjenester: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/Tjenester/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        heading
                        paragraph
                        forretningsforseltitle
                        forsikringtitle
                        drifttitle
                        medlemsfordelertitle
                    }
                }
            }
        }
        contact: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/CMSContent/Contact/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        heading
                        contentKunder
                        contentAndre
                        contentJobb
                        subheadingKunder
                        subheadingJobb
                        subheadingAndre
                        customerPicture {
                            childImageSharp {
                                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
    }
`
