import React from "react"
import { SvgProps } from "../../interface/svg"

const IllustrationGiftBox: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgProps.width ? SvgProps.width : '250px'}
            height={SvgProps.height ? SvgProps.height : '191px'}
            viewBox="0 0 250 191"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fillRule="nonzero" transform="translate(-887 -2340)">
                    <g transform="translate(150 1918)">
                        <g transform="translate(737 422)">
                            <path
                                fill="#ABC3F5"
                                d="M196.258 91.241H207.83700000000002V102.82H196.258z"
                                transform="rotate(150 202.047 97.03)"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M204.294 107.029l-6.046-10.47 10.471-6.046 6.046 10.47-10.471 6.046zm-5.347-10.284l5.534 9.585 9.585-5.534-5.534-9.584-9.585 5.533z"
                            ></path>
                            <circle
                                cx="194.913"
                                cy="130.307"
                                r="4.859"
                                fill="#ABC3F5"
                            ></circle>
                            <path
                                fill="#131E46"
                                d="M198.493 135.422a5.115 5.115 0 110-10.23 5.115 5.115 0 010 10.23zm0-9.719a4.604 4.604 0 100 9.207 4.604 4.604 0 000-9.207z"
                            ></path>
                            <circle
                                cx="67.263"
                                cy="100.963"
                                r="27.11"
                                fill="#ABC3F5"
                            ></circle>
                            <path
                                fill="#253260"
                                d="M59.939 140.551l6.669 4.132V116.75l-7.475-4.632a2.9 2.9 0 01-1.265-1.684 2.9 2.9 0 012.07.383l6.67 4.133v-10.832c0-2.095.257-4.183.765-6.216.508 2.033.765 4.12.765 6.216v17.68l9.838-6.097a4.821 4.821 0 012.619-.722 4.821 4.821 0 01-1.813 2.023l-10.644 6.596v54.978h-1.53v-32.092l-7.475-4.632a2.9 2.9 0 01-1.265-1.684 2.9 2.9 0 012.07.383z"
                            ></path>
                            <circle
                                cx="27.11"
                                cy="103.265"
                                r="27.11"
                                fill="#ABC3F5"
                            ></circle>
                            <path
                                fill="#253260"
                                d="M34.435 142.853l-6.67 4.132v-27.933l7.476-4.632a2.9 2.9 0 001.265-1.684 2.9 2.9 0 00-2.071.383l-6.67 4.133V106.42c0-2.095-.256-4.183-.764-6.216a25.628 25.628 0 00-.766 6.216v17.68l-9.838-6.097a4.821 4.821 0 00-2.618-.723 4.821 4.821 0 001.812 2.024l10.644 6.596v54.978h1.53v-32.092l7.476-4.632a2.9 2.9 0 001.265-1.685 2.9 2.9 0 00-2.071.384z"
                            ></path>
                            <ellipse
                                cx="98.721"
                                cy="181.228"
                                fill="#253260"
                                rx="76.471"
                                ry="3.734"
                            ></ellipse>
                            <ellipse
                                cx="167.519"
                                cy="187.095"
                                fill="#253260"
                                rx="76.471"
                                ry="3.734"
                            ></ellipse>
                            <path
                                fill="#FFB8B8"
                                d="M119.693096 175.703327L118.15857 183.120207 124.552432 183.120207 123.785168 175.703327z"
                            ></path>
                            <path
                                fill="#FFB8B8"
                                d="M97.1867022 173.145783L95.6521753 180.562663 102.046037 180.562663 101.278774 173.145783z"
                            ></path>
                            <path
                                fill="#FFB8B8"
                                d="M106.522 108.056s2.046 8.952 4.092 11.765c2.046 2.813 6.905 10.997 6.905 10.997s1.023 10.23 4.86 7.929c3.836-2.302-2.047-9.719-2.047-9.719l-7.416-16.368-1.535-5.627-4.86 1.023z"
                            ></path>
                            <circle
                                cx="117.136"
                                cy="78.005"
                                r="6.905"
                                fill="#FFB8B8"
                            ></circle>
                            <path
                                fill="#FFB8B8"
                                d="M118.798 82.737l-3.325 7.416-6.905-1.79s5.37-5.882 4.603-8.44l5.627 2.814z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M107.8 87.084s8.185.256 10.23 3.07c2.047 2.813 3.07 4.092 3.07 4.092s2.813.767 3.069 4.347c.256 3.58.767 9.463.767 9.463s6.138 6.65 7.161 19.438c1.023 12.787 9.719 50.383 0 50.639-9.718.256-16.624 3.836-15.345-.767 1.279-4.604 3.325-31.202 3.325-31.202s-1.535 32.736-7.673 32.48c-6.138-.255-14.066-4.347-15.857-4.859-1.79-.511 13.044-31.97 13.044-31.97l1.79-27.365s-13.043-23.018-3.58-27.366z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M105.242968 94.7570347L106.521741 111.381076 112.148339 111.381076z"
                            ></path>
                            <path
                                fill="#FFB8B8"
                                d="M115.985 107.545s2.046 8.951 4.092 11.764c2.046 2.814 6.905 10.998 6.905 10.998s1.023 10.23 4.86 7.928c3.836-2.301-2.047-9.718-2.047-9.718l-7.416-16.369-1.535-5.626-4.86 1.023z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M120.332 181.714s-1.79-2.046-2.301-1.023c-.512 1.023-2.814 6.138.511 6.138s17.903-.768 16.113-3.837c-1.79-3.069-4.348-2.301-4.348-2.301h-3.325s-2.393-2.671-2.859-1.72c-.466.952-1.233 2.487-1.233 2.487s-1.279 1.279-2.558.256zM96.911 177.213s-.446-2.682-1.419-2.081c-.973.6-5.622 3.74-2.794 5.489 2.827 1.75 15.628 8.766 15.72 5.214.093-3.551-2.486-4.244-2.486-4.244l-2.828-1.75s-.63-3.53-1.526-2.966c-.897.565-2.357 1.466-2.357 1.466s-1.76.415-2.31-1.128zM112.916 88.619s-1.79 3.58 0 9.974a346.547 346.547 0 013.069 11.765s4.092-2.557 5.882-1.79c1.79.767-2.832-17.045-2.832-17.045s-2.795-5.973-6.12-2.904zM125.46 75.851s2.476-8.644-7.209-7.161c0 0-6.417-2.217-9.84 5.71l-3.512 8.323 1.157-.418.332 1.164 1.665.703.965-1.358.086 1.84 13.965 3.575s-5.779-4.724-4.854-11.471l1.015 1.463 6.23-2.37z"
                            ></path>
                            <path
                                fill="#1657E2"
                                d="M176.463 139.023H227.614V186.849H176.463z"
                            ></path>
                            <path
                                fill="#253260"
                                d="M196.667 137.724H207.409V188.385H196.667z"
                            ></path>
                            <circle
                                cx="46.803"
                                cy="46.677"
                                r="46.677"
                                fill="#ABC3F5"
                            ></circle>
                            <path
                                fill="#253260"
                                d="M59.414 114.837l-11.482 7.115V73.858l12.87-7.975a4.992 4.992 0 002.178-2.9 4.992 4.992 0 00-3.566.66l-11.482 7.115v-18.65c0-3.607-.442-7.201-1.317-10.701a44.125 44.125 0 00-1.318 10.702v30.439L28.36 72.05a8.301 8.301 0 00-4.509-1.243 8.301 8.301 0 003.12 3.483l18.327 11.357v94.659h2.635V125.05l12.87-7.974a4.992 4.992 0 002.178-2.9 4.992 4.992 0 00-3.566.66zM71.044 173.794c-.301.006-.593.11-.83.297-.707-1.899-1.802-3.12-3.033-3.12-1.23 0-2.325 1.221-3.033 3.12a1.394 1.394 0 00-.83-.297c-1.398 0-2.53 2.4-2.53 5.362h12.787c0-2.961-1.133-5.362-2.53-5.362zM39.075 177.375c-.302.006-.593.11-.83.296-.708-1.898-1.803-3.119-3.033-3.119s-2.326 1.22-3.033 3.12a1.394 1.394 0 00-.83-.297c-1.398 0-2.531 2.4-2.531 5.362h12.788c0-2.962-1.133-5.362-2.531-5.362zM238.07 134.566c-.274 4.512-6.368 7.811-6.368 7.811s-5.65-4.015-5.374-8.527c.275-4.511 6.37-7.81 6.37-7.81s5.648 4.014 5.373 8.526z"
                            ></path>
                            <path
                                fill="#253260"
                                d="M238.347 148.642c-4.513-.26-7.831-6.344-7.831-6.344s3.997-5.661 8.51-5.4c4.512.26 7.83 6.344 7.83 6.344s-3.997 5.661-8.51 5.4z"
                            ></path>
                            <path
                                fill="#000"
                                d="M227.613814 144.245526L227.613814 160.941179 211.007676 139.02302 223.657292 139.02302z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#1657E2"
                                d="M196.268 140.647H257.649V151.13299999999998H196.268z"
                                transform="rotate(52.85 226.959 145.89)"
                            ></path>
                            <path
                                fill="#253260"
                                d="M221.302 139.548H232.29899999999998V150.54500000000002H221.302z"
                                transform="rotate(52.85 226.801 145.047)"
                            ></path>
                            <path
                                fill="#ABC3F5"
                                d="M181.076 105.241H192.655V116.82H181.076z"
                                transform="rotate(-165 186.865 111.03)"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M186.214 119.794l-11.678-3.13 3.13-11.679 11.678 3.13-3.13 11.679zm-11.052-3.491l10.69 2.864 2.865-10.69-10.69-2.865-2.865 10.69z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationGiftBox
