import React from "react"
import { SvgProps } from "../../interface/svg"

const IllustrationDevProductivity: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgProps.width ? SvgProps.width : '230px'}
            height={SvgProps.height ? SvgProps.height : '196px'}
            viewBox="0 0 230 196"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-321 -2000)">
                    <g transform="translate(150 1918)">
                        <g transform="translate(171 82)">
                            <circle
                                cx="118.225"
                                cy="37.002"
                                r="22.98"
                                fill="#FFB8B8"
                                fillRule="nonzero"
                            ></circle>
                            <path
                                fill="#FFB8B8"
                                fillRule="nonzero"
                                d="M101.074 48.492s4.33 32.638-2.332 36.301c-6.66 3.664 39.633 3.997 39.633 3.997s-10.325-28.309-5.662-36.968l-31.64-3.33z"
                            ></path>
                            <path
                                fill="#ABC3F5"
                                fillRule="nonzero"
                                d="M172.178 88.955l-3.85 17.531-8.14 37.09-.244 2.032-2.81 23.516-1.868 15.622-1.253.493c-7.62 3.916-13.141 6.62-13.141 6.62s-.475-2.382-1.24-5.429c-5.348 1.603-15.241 4.263-23.626 4.794 3.053 3.403-26.903 1.172-35.197-4.385a22.45 22.45 0 00-.218 4.02l-6.347-4.882.628-1.231 1.06-19.003 1.33-23.832a25.02 25.02 0 01-1.053-3.134c-1.943-6.913-4.303-20.716-6.112-32.29a1129.486 1129.486 0 01-2.781-18.863l-.047-.331 35.134-15.525c2.497 5.79 14.156 7.53 14.156 7.53 9.66-.665 17.232-5.759 17.232-5.759l38.387 15.416z"
                            ></path>
                            <path
                                fill="#A0616A"
                                fillRule="nonzero"
                                d="M143.538 175.199s-1.488.51-3.907 1.231c-5.347 1.603-15.24 4.263-23.625 4.794-7.218.462-13.318-.65-14.434-5.36-.478-2.025.2-3.69 1.678-5.059 4.413-4.09 15.938-5.556 25.078-6.06a167.816 167.816 0 0112.544-.206l.053.207 2.613 10.453z"
                            ></path>
                            <path
                                fill="#131E46"
                                fillRule="nonzero"
                                d="M99.108 15.265l-4.296-1.72s8.984-9.891 21.483-9.031L112.78.644s8.593-3.44 16.405 5.59c4.107 4.747 8.858 10.327 11.82 16.613h4.602l-1.92 4.229 6.721 4.229-6.9-.76a23.63 23.63 0 01.188 7.014 9.075 9.075 0 01-3.323 5.937s-5.329-11.03-5.329-12.75v4.3s-4.297-3.87-4.297-6.45l-2.343 3.01-1.172-4.73-14.452 4.73 2.343-3.87-8.984 1.29 3.516-4.73s-10.156 5.59-10.547 10.32c-.39 4.73-3.363 9.213-3.363 9.213s-7.964-22.114 3.363-28.564z"
                            ></path>
                            <path
                                fill="#ABC3F5"
                                fillRule="nonzero"
                                d="M197.156 169.552c-2.34 2.537-8.14 6.36-15.172 10.497a380.31 380.31 0 01-8.24 4.697c-6.778 3.759-13.875-6.516-19.731-3.507-7.62 3.916-13.141 6.62-13.141 6.62s-.475-2.382-1.24-5.429c-1.026-4.078-2.57-9.35-4.179-11.684-.056-.082-.112-.157-.169-.232-.468-.615-.94-.975-1.406-.975l23.256-.415 10.05-6.231-7.24-17.285-9.081-21.681 5.48-17.44 5.51-17.532h10.325s3.419 7.463 7.79 17.869a666.752 666.752 0 012.016 4.853c8.994 21.875 20.207 52.422 15.172 57.875z"
                            ></path>
                            <path
                                fill="#A0616A"
                                fillRule="nonzero"
                                d="M118.56 190.183c-5.744 7.113-29.457-7.787-37.75-13.344-1.807-1.209-2.882-1.975-2.882-1.975l7.588-10.118 2.403-3.203a165.714 165.714 0 005.678 3.203c2.74 1.609 6.181 3.712 9.653 6.059 4.79 3.24 9.634 6.938 12.756 10.419 3.053 3.403 4.457 6.6 2.553 8.96z"
                            ></path>
                            <path
                                fill="#ABC3F5"
                                fillRule="nonzero"
                                d="M93.247 170.54a14.932 14.932 0 00-2.531.206c-7.185 1.234-9.303 7.737-9.907 12.093a22.45 22.45 0 00-.218 4.02l-6.347-4.882-2.313-1.778c-5.565-1.94-10.525 8.612-14.825 4.547a74.526 74.526 0 01-9.984-11.841 104.422 104.422 0 01-7.725-13.25 9.745 9.745 0 01-.134-8.266l7.859-17.515 11.487-25.6c.085-.61.178-1.205.279-1.788 2.278-13.062 8.38-18.862 8.38-18.862h4.663l3.12 18.862 3.874 23.435-2.716 8.856-6.275 20.453 5.997 6.513 17.316 4.796z"
                            ></path>
                            <path
                                fill="#1657E2"
                                fillRule="nonzero"
                                d="M98.524 119.94l3.986 4.65c1.48-.283 3.027-.615 4.62-.987l-1.145-3.664 2.8 3.266c16.874-4.15 37.789-12.22 37.789-12.22s-22.58 1.657-39.44-2.027c-7.46-1.63-15.135 2.068-18.196 9.063-1.78 4.066-1.5 7.589 4.81 7.589a41.68 41.68 0 006.361-.598l-1.585-5.073z"
                            ></path>
                            <path
                                fill="#131E46"
                                fillRule="nonzero"
                                d="M189.39 186.37v1.898a4.191 4.191 0 01-4.196 4.196H45.647a4.2 4.2 0 01-3.913-2.675 4.191 4.191 0 01-.284-1.521v-1.897a4.197 4.197 0 014.197-4.197h8.044v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.621v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.625v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885H75.2v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.622v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.625v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.621v-.885a.175.175 0 01.175-.175h32.875a.175.175 0 01.175.175v.885h2.625v-.885a.175.175 0 01.175-.175h4.197c.097.001.174.08.175.175v.885h2.622v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.622v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h2.625v-.885a.175.175 0 01.175-.175h4.197c.095.002.172.08.172.175v.885h2.625v-.885a.175.175 0 01.175-.175h4.196a.175.175 0 01.175.175v.885h2.622v-.885a.175.175 0 01.175-.175h4.197a.175.175 0 01.175.175v.885h12.24a4.196 4.196 0 014.198 4.197z"
                            ></path>
                            <path
                                fill="#3F3D56"
                                fillRule="nonzero"
                                d="M0.906 189.789H229.96800000000002V190.789H0.906z"
                            ></path>
                            <path
                                fill="#131E46"
                                fillRule="nonzero"
                                d="M178.155 106.486H126.14v-1.072h-23.585v1.072H50.328a3.518 3.518 0 00-3.518 3.519v71.222a3.518 3.518 0 003.518 3.518h127.827a3.518 3.518 0 003.518-3.518v-71.222a3.518 3.518 0 00-3.518-3.519z"
                            ></path>
                            <circle
                                cx="114.344"
                                cy="131.352"
                                r="7.813"
                                stroke="#D0CDE1"
                                strokeWidth="0.625"
                            ></circle>
                            <circle
                                cx="111.844"
                                cy="134.164"
                                r="7.813"
                                fill="#D0CDE1"
                                fillRule="nonzero"
                            ></circle>
                            <path
                                fill="#ABC3F5"
                                fillRule="nonzero"
                                d="M7.139 186.632H15.889V195.382H7.139z"
                            ></path>
                            <path
                                fill="#3F3D56"
                                fillRule="nonzero"
                                d="M10.578 179.757v10.625h10.625v-10.625H10.578zm10.163 10.163h-9.703v-9.7h9.703v9.7z"
                            ></path>
                            <path
                                fill="#ABC3F5"
                                fillRule="nonzero"
                                d="M209.639 186.632H218.389V195.382H209.639z"
                            ></path>
                            <path
                                fill="#3F3D56"
                                fillRule="nonzero"
                                d="M213.078 179.757v10.625h10.625v-10.625h-10.625zm10.163 10.163h-9.703v-9.7h9.703v9.7z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationDevProductivity
