import React, { Fragment } from "react"
import SplitGrid from "../split-column-grid"
import { IKontaktProps } from "./../../interface/graphs"
import { toHTML } from "../../helpers"

const Contact: React.FC<{ data: IKontaktProps }> = ({ data }) => {
    const { contentKunder, customerPicture, subheadingKunder } =
        data?.node?.frontmatter
    return (
        <Fragment>
            <div>
                <SplitGrid
                    text={contentKunder && toHTML(contentKunder)}
                    img={customerPicture?.childImageSharp?.gatsbyImageData}
                    subtitle={subheadingKunder && subheadingKunder}
                    orientation={1}
                    button={true}
                ></SplitGrid>
            </div>
        </Fragment>
    )
}

export default Contact
