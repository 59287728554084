import React, { Fragment } from "react"
import {
    StyledHeadline,
    StyledCardGridWrapper,
} from "../../styles/global"
import CardGrid from "../card-grid"

const ServiceAreas: React.FC<{ data: any }> = ({ data }) => {
    const title = data.node.frontmatter.heading
    return (
        <Fragment>
            <div id="tjenester" />
                <StyledHeadline>
                    <h2 className="bbl-title">{title}</h2>
                </StyledHeadline>
            <StyledCardGridWrapper>
                <CardGrid data={data} />
            </StyledCardGridWrapper>
        </Fragment>
    )
}

export default ServiceAreas
