import { SvgProps } from "../../interface/svg"
import React from "react"

const IllustrationSocial: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgProps.width ? SvgProps.width : '543px'}
            height={SvgProps.height ? SvgProps.height : '283px'}
            viewBox="0 0 543 283"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fillRule="nonzero" transform="translate(-769 -2903)">
                    <g transform="translate(0 2734)">
                        <g transform="matrix(-1 0 0 1 1312 169)">
                            <path
                                fill="#3F3D56"
                                d="M529.388 273.683l-.488-.183c-.108-.04-10.794-4.134-15.8-13.418-5.005-9.284-2.554-20.461-2.529-20.573l.115-.509.489.183c.107.04 10.793 4.135 15.799 13.418 5.006 9.284 2.555 20.462 2.53 20.573l-.116.51zm-15.486-14.033c4.232 7.85 12.677 11.873 14.816 12.791.407-2.293 1.683-11.566-2.546-19.408-4.228-7.84-12.675-11.87-14.816-12.79-.407 2.294-1.682 11.566 2.546 19.407z"
                                transform="matrix(-1 0 0 1 1040.075 0)"
                            ></path>
                            <path
                                fill="#1657E2"
                                d="M530.04 256.826c8.995 5.412 12.46 16.163 12.46 16.163s-11.12 1.975-20.117-3.438c-8.996-5.412-12.46-16.162-12.46-16.162s11.12-1.976 20.116 3.437z"
                                transform="matrix(-1 0 0 1 1052.422 0)"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M328.263137 249.810874L314.605489 248.900364 319.158039 269.386837 334.636707 263.013267z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M453.458247 211.569459L421.590401 180.612123 403.380203 194.725026 367.415062 237.974246 371.512357 244.347815 415.216831 214.756243 444.808403 253.452914 473.034209 239.34001z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M453.458247 211.569459L421.590401 180.612123 403.380203 194.725026 367.415062 237.974246 371.512357 244.347815 415.216831 214.756243 444.808403 253.452914 473.034209 239.34001z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M456.19 232.056l-4.098 3.187-33.233-11.382-7.74-3.186-15.478 3.186s0-12.747 14.568-12.292l15.023 3.642 17.755 1.366 13.203 15.479z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M485.781348 265.744797L475.765739 272.118366 463.92911 272.118366 408.843262 251.631894 328.263137 266.200052 325.986863 248.900364 399.738163 216.577263 464.384365 237.974246z"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M421.135 184.71l-6.829 1.365-1.488-1.736a9.199 9.199 0 01-1.243-10.1l19.12-25.04s5.463-12.291 14.113-7.284c8.65 5.008-7.284 13.203-7.284 13.203l-9.56 15.023.91 7.74-7.739 6.828z"
                            ></path>
                            <circle
                                cx="464.384"
                                cy="137.363"
                                r="17.755"
                                fill="#FFB9B9"
                            ></circle>
                            <path
                                fill="#FFB9B9"
                                d="M456.417 150.338l8.195 19.576 19.576-8.195s-6.374-15.479-6.374-15.934c0-.455-21.397 4.553-21.397 4.553z"
                            ></path>
                            <path
                                fill="#739AEE"
                                d="M510.324 208.25c0 10.716-1.689 21.364-5.003 31.554l-1.33 4.089-6.373 29.136-6.829 1.82-5.008-6.373-10.015-7.284-7.284-11.836-6.247-10.408-1.948-3.25-6.829-34.144-25.95-14.568-8.65-1.366 1.822-5.008 7.74-10.47 7.738-.911 2.277-1.821 1.82.91 7.285-4.097 2.276.91 13.312 1.239 8.085-1.694 11.627-6.241 4.762.778 16.39 13.658a101.924 101.924 0 016.332 35.378z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M318.798 256.86s4.077-6.91 2.355-7.717c-1.722-.807-9.036-4.024-9.036-4.024l-10.727-3.554s-15.477-23.003-15.817-3c-.34 20.004 11.27 24.812 11.27 24.812s10.353 22.283 16.219 18.73c5.865-3.554 11.733-15.615 11.733-15.615s-10.576-5.686-5.997-9.632z"
                            ></path>
                            <path
                                fill="#000"
                                d="M505.35731 204.28538L488.057622 215.666753 462.235562 238.948491 460.287071 235.697971 454.824011 208.382674 456.189776 208.382674 470.30268 182.433143z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#739AEE"
                                d="M470.30268 179.701613L456.189776 205.651145 451.181972 205.651145 447.995187 214.756243 440.711108 216.577263 460.287071 237.974246 488.057622 212.935224 505.35731 201.55385z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M450.575 124.161l-1.924.74a2.685 2.685 0 01-.687-3.774l-3.555-.167a26.844 26.844 0 0140.075-3.23c.162-.557 1.926.527 2.647 1.627.242-.907 1.896 3.478 2.481 6.577.27-1.032 1.312.634.4 2.233.578-.085.84 1.395.392 2.219.632-.298.526 1.468-.16 2.648.902-.08-7.118 12.368-10.366 19.643-.943-4.332-2.003-8.895-5.044-12.122-.516-.549-1.082-1.048-1.647-1.547l-3.057-2.698c-3.553-3.137-6.786-7.008-11.52-8.18-3.253-.806-5.31-.988-3.538-4.654-1.601.668-3.097 1.662-4.714 2.283.022-.512.248-1.085.217-1.598z"
                            ></path>
                            <circle
                                cx="473.262"
                                cy="139.412"
                                r="3.187"
                                fill="#FFB9B9"
                            ></circle>
                            <path
                                fill="#131E46"
                                d="M166.048 156.327a41.968 41.968 0 01-16.133-3.158c-14.274-5.95-23.726-18.763-32.868-31.154-4.935-6.69-10.038-13.607-15.867-19.43-9.34-9.333-20.178-15.363-31.339-17.437-12.543-2.332-25.324.684-34.19 8.064a32.088 32.088 0 00-3.161 3.022c1.293.157 2.584.325 3.87.504 13.537 1.876 30.514 7.078 34.52 21.213 1.537 5.425.443 11.567-3.002 16.85-3.692 5.66-9.568 9.552-15.717 10.41a21.85 21.85 0 01-3.024.21c-11.074 0-21.985-8.232-25.216-19.548-2.736-9.582.024-20.749 6.756-28.936a238.938 238.938 0 00-24.51-1.523l.01-.91c8.425.09 16.84.623 25.21 1.599a33.011 33.011 0 013.682-3.59c9.071-7.552 22.132-10.64 34.939-8.26 11.342 2.108 22.344 8.224 31.816 17.687 5.877 5.873 11 12.818 15.956 19.534 9.485 12.857 18.444 25.001 32.486 30.855 14.113 5.883 31.442 3.206 43.122-6.66 11.682-9.865 17.22-26.503 13.78-41.401l.887-.205c3.514 15.222-2.144 32.222-14.079 42.302-7.713 6.515-17.839 9.962-27.928 9.962zM31.758 97.063c-6.824 7.998-9.668 19.081-6.961 28.56 3.404 11.926 15.625 20.308 27.238 18.687 5.892-.823 11.53-4.564 15.08-10.007 3.301-5.062 4.355-10.932 2.889-16.104-3.872-13.661-20.5-18.72-33.769-20.56-1.487-.206-2.98-.398-4.477-.576z"
                                transform="scale(-1 1) rotate(-25 0 605.368)"
                            ></path>
                            <g transform="translate(11)">
                                <path
                                    fill="#EEF4FB"
                                    d="M42.105 56.476L3.659 36.706a5.345 5.345 0 01-2.31-7.197l9.058-17.614a2.118 2.118 0 011.29-1.065L42 1.995a4.93 4.93 0 016.033 3.102l9.956 28.4a5.535 5.535 0 01-.301 4.362l-8.385 16.307a5.345 5.345 0 01-7.198 2.31z"
                                ></path>
                                <path
                                    fill="#000"
                                    d="M43.632 49.495L4.813 32.922c-1.25-.643-.078.346.566-.905l4.88-19.833 47.553 24.452L45.79 51.501c-.644 1.251-.907-1.363-2.158-2.006z"
                                    opacity="0.1"
                                ></path>
                                <rect
                                    width="39.767"
                                    height="39.767"
                                    x="12.992"
                                    y="7.151"
                                    fill="#739AEE"
                                    rx="2.941"
                                    transform="rotate(-62.787 32.875 27.035)"
                                ></rect>
                                <path
                                    fill="#000"
                                    d="M51.4589144 34.3738348L41.4647826 53.8098827 6.09963293 35.6244934 16.0937647 16.1888048 29.3817886 33.8280863z"
                                    opacity="0.1"
                                ></path>
                                <path
                                    fill="#EEF4FB"
                                    d="M28.817 34.927L11.79 12.325a1.034 1.034 0 00-1.745.15L.357 31.316a3.228 3.228 0 001.395 4.346l42.212 21.706a3.228 3.228 0 004.346-1.394l9.522-18.519a1.261 1.261 0 00-1.09-1.837l-27.925-.691z"
                                ></path>
                                <path
                                    fill="#F2F2F2"
                                    d="M20.21 17.259H53.237V18.259H20.21z"
                                    transform="rotate(27.213 36.723 17.708)"
                                ></path>
                                <path
                                    fill="#F2F2F2"
                                    d="M18.823 19.956H51.85V20.956H18.823z"
                                    transform="rotate(27.213 35.336 20.406)"
                                ></path>
                                <path
                                    fill="#F2F2F2"
                                    d="M17.436 22.654H50.463V23.654H17.436z"
                                    transform="rotate(27.213 33.95 23.103)"
                                ></path>
                            </g>
                            <path
                                fill="#131E46"
                                d="M348.817 175.251a120.223 120.223 0 01-18.036-1.313c-14.723-2.133-27.952-7.544-37.554-15.362-3.17-2.557-6.056-5.428-8.847-8.205-4.238-4.215-8.62-8.574-14.002-11.817-5.356-3.227-12.408-4.924-18.399-4.435-4.41.34-8.37 1.98-10.811 4.479l-1.116-.513c2.656-2.718 6.965-4.501 11.764-4.87 6.46-.529 13.74 1.212 19.462 4.66 5.507 3.319 9.937 7.724 14.22 11.985 2.773 2.759 5.64 5.61 8.766 8.133 18.23 14.708 47.184 19.847 79.443 14.101l.345.88a144.546 144.546 0 01-25.235 2.277z"
                                transform="scale(-1 1) rotate(-15 0 2486.475)"
                            ></path>
                            <circle
                                cx="223.043"
                                cy="147.638"
                                r="7.289"
                                fill="#ABC3F5"
                            ></circle>
                            <path
                                fill="#131E46"
                                d="M225.414 150.81a7.673 7.673 0 117.672-7.672 7.681 7.681 0 01-7.672 7.673zm0-14.577a6.905 6.905 0 106.905 6.905 6.913 6.913 0 00-6.905-6.905z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationSocial
