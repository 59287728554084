import { SvgProps } from "../../interface/svg"
import React from 'react'

const IllustrationSecurity: React.FC<SvgProps> = ({ ...SvgProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SvgProps.width ? SvgProps.width : '284px'}
            height={SvgProps.height ? SvgProps.height : '195px'}
            viewBox="0 0 284 195"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-874 -2000)">
                    <g transform="translate(150 1918)">
                        <g fillRule="nonzero" transform="translate(724 82)">
                            <ellipse
                                cx="76.471"
                                cy="179.734"
                                fill="#253260"
                                rx="76.471"
                                ry="3.734"
                            ></ellipse>
                            <ellipse
                                cx="194.471"
                                cy="185.602"
                                fill="#253260"
                                rx="76.471"
                                ry="3.734"
                            ></ellipse>
                            <g transform="translate(65.553 22.557)">
                                <path
                                    fill="#131E46"
                                    d="M61.5.638L.7 38.052s0 77.167 60.798 119.258c60.798-42.09 60.798-119.258 60.798-119.258L61.5.638z"
                                ></path>
                                <path
                                    fill="#1657E2"
                                    d="M61.862.638l-1.04.64V156.58c.346.244.691.488 1.04.73C122.66 115.22 122.66 38.052 122.66 38.052L61.862.638z"
                                    opacity="0.1"
                                ></path>
                                <path
                                    fill="#1657E2"
                                    d="M46.7840393 76.6495318L57.8236035 86.8043065 73.4630975 63.7249313 78.9830134 68.34086 57.8236035 96.036164 42.1841094 78.4959033z"
                                ></path>
                            </g>
                            <path
                                fill="#4D8AF0"
                                d="M70.001 6.223a1.023 1.023 0 01-.57-1.236.513.513 0 00-.9-.453h.001a.492.492 0 00-.057.1 1.023 1.023 0 01-1.236.57.513.513 0 00-.453.9.491.491 0 00.1.056c.477.208.722.74.57 1.236a.513.513 0 00.9.453.492.492 0 00.056-.1 1.023 1.023 0 011.236-.57.513.513 0 00.453-.9v.001a.492.492 0 00-.1-.057zM29.368 96.672a1.023 1.023 0 01-.57-1.236.513.513 0 00-.9-.453h.002a.492.492 0 00-.057.1 1.023 1.023 0 01-1.236.57.513.513 0 00-.453.9.491.491 0 00.1.056c.477.208.722.74.57 1.236a.513.513 0 00.9.453.492.492 0 00.056-.1 1.023 1.023 0 011.236-.57.513.513 0 00.453-.9v.001a.492.492 0 00-.1-.057z"
                                opacity="0.5"
                            ></path>
                            <circle
                                cx="252.466"
                                cy="51.694"
                                r="1.67"
                                fill="#ABC3F5"
                                opacity="0.5"
                            ></circle>
                            <circle
                                cx="57.786"
                                cy="182.959"
                                r="1.67"
                                fill="#ABC3F5"
                                opacity="0.5"
                            ></circle>
                            <circle
                                cx="280.853"
                                cy="115.983"
                                r="1.67"
                                fill="#ABC3F5"
                                opacity="0.5"
                            ></circle>
                            <path
                                fill="#4D8AF0"
                                d="M216.446 2.422a1.023 1.023 0 01-.57-1.236.513.513 0 00-.9-.453.492.492 0 00-.056.1 1.023 1.023 0 01-1.236.57.513.513 0 00-.453.9.491.491 0 00.1.056c.477.207.722.739.57 1.236a.513.513 0 00.9.453.492.492 0 00.056-.1 1.023 1.023 0 011.236-.57.513.513 0 00.453-.9.492.492 0 00-.1-.056zM268.02 177.198a1.023 1.023 0 01-.57-1.236.513.513 0 00-.9-.453h.002a.492.492 0 00-.057.1 1.023 1.023 0 01-1.236.57.513.513 0 00-.453.9v-.001a.491.491 0 00.1.057c.477.207.722.739.57 1.236a.513.513 0 00.9.453.492.492 0 00.056-.1 1.023 1.023 0 011.236-.57.513.513 0 00.453-.9.492.492 0 00-.1-.056zM27.42 43.794a1.023 1.023 0 01-.57-1.236.513.513 0 00-.9-.453h.001a.492.492 0 00-.056.1 1.023 1.023 0 01-1.236.57.513.513 0 00-.453.9.491.491 0 00.1.057c.476.207.721.739.57 1.235a.513.513 0 00.9.453h-.001a.492.492 0 00.057-.1 1.023 1.023 0 011.236-.57.513.513 0 00.453-.9v.001a.492.492 0 00-.1-.057z"
                                opacity="0.5"
                            ></path>
                            <circle
                                cx="46.097"
                                cy="71.915"
                                r="1.67"
                                fill="#ABC3F5"
                                opacity="0.5"
                            ></circle>
                            <circle
                                cx="281.688"
                                cy="73.402"
                                r="1.67"
                                fill="#ABC3F5"
                                opacity="0.5"
                            ></circle>
                            <g
                                fill="#ABC3F5"
                                opacity="0.5"
                                transform="translate(156.998 4.749)"
                            >
                                <path d="M2.059 0H3.059V4.731H2.059z"></path>
                                <path
                                    d="M2.059 0H3.059V4.731H2.059z"
                                    transform="rotate(90 2.477 2.366)"
                                ></path>
                            </g>
                            <g
                                fill="#ABC3F5"
                                opacity="0.5"
                                transform="translate(29.288 140.758)"
                            >
                                <path d="M2.059 0H3.059V4.731H2.059z"></path>
                                <path
                                    d="M2.059 0H3.059V4.731H2.059z"
                                    transform="rotate(90 2.477 2.366)"
                                ></path>
                            </g>
                            <g
                                fill="#ABC3F5"
                                opacity="0.5"
                                transform="translate(239.553 91.213)"
                            >
                                <path d="M2.059 0H3.059V4.731H2.059z"></path>
                                <path
                                    d="M2.059 0H3.059V4.731H2.059z"
                                    transform="rotate(90 2.477 2.366)"
                                ></path>
                            </g>
                            <path
                                fill="#ABC3F5"
                                d="M265.603935 15.2411152L267.397055 16.8847857 269.937425 13.1488167 270.834124 13.8960662 267.397055 18.3792846 264.856685 15.540015z"
                                opacity="0.5"
                            ></path>
                            <path
                                fill="#6C63FF"
                                d="M208.38 47.712H214.57V55.67100000000001H208.38z"
                                transform="rotate(-2.221 211.475 51.691)"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M186.51 75.448s-8.565-4.093-8.428-.559c.137 3.535 8.634 5.86 8.634 5.86l-.205-5.301z"
                            ></path>
                            <path
                                fill="#1B2754"
                                d="M191.21 105.354s-4.041 9.892-5.534 17.03c-1.493 7.137-3.767 16.96-.842 23.926 2.925 6.967 11.241 27.441 11.327 29.65.085 2.21-.713 4.453 1.055 4.384 1.767-.068 11.384-3.096 11.775-4.439.39-1.342-3.282-4.74-3.282-4.74l-9.066-28.41 9.46-18.509 6.822 27.612 1.337 34.462s6.559-2.024 7.51-.29c0 0 2.94-4.097 3.313-5.882.373-1.784-1.388-35.787-1.388-35.787s6.02-38.73 1.16-38.541c-4.86.188-23.896-11.463-33.647-.466z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M197.147 178.577s-4.483 9.908-4.908 10.367c-.424.46-3.346 4.997-1.137 4.912 2.21-.086 7.426-2.5 11.265-6.19 3.84-3.688 8.901-10.08 8.442-10.504-.459-.425-3.603-1.63-3.603-1.63l-10.059 3.045zM215.06 184.077s-.27 4.435-1.086 6.237c-.815 1.801-.712 4.452 1.497 4.367 2.21-.086 7.919-1.192 7.919-1.192s.339-2.668-.12-3.093c-.46-.425-2.415-5.216-1.583-6.576.832-1.36-6.627.257-6.627.257z"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M218.328 40.142s4.71 7.34 6.545 9.039c1.836 1.698-3.329 5.439-3.329 5.439l-9.244 1.243s.117-8.412-.36-9.278c-.476-.867 6.388-6.443 6.388-6.443z"
                            ></path>
                            <path
                                fill="#1657E2"
                                d="M210.293 49.746s1.905 3.466 3.672 3.398c1.767-.069 10.908-3.963 11.299-5.306.39-1.342 3.028 9.617 3.028 9.617L205.924 96.82l-5.456-3.77 2.643-23.113 2.172-12.473 5.01-7.717z"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M226.089 84.975c-.892 1.866-1.45 3.813-1.372 5.805.003.089.006.177.014.265.212 4.09 1.718 8.819 2.259 12.258.392 2.467.28 4.268-1.163 4.691-3.5 1.021.51 1.75-13.032 6.258-13.543 4.508-15.906.617-15.94-.267-.034-.883 1.918-7.596 1.459-8.021-.46-.425-6.422 5.559-8.224 4.744-1.801-.815-.205-5.302-.205-5.302s4.058-9.45 3.973-11.659c-.086-2.209 6.147-12.628 6.147-12.628l4.24-27.598s1.63-3.604 3.822-4.131c2.192-.528 4.01.73 4.01.73l-3.667 8.106-3.87 14.31.647 5.285s4.5-9.467 7.456-13.121c2.956-3.655 9.257-12.307 9.257-12.307s1.472-4.964.361-6.447c-1.11-1.488 4.633-1.711 7.082 4.389 1.298 3.233 3.405 8.054 4.725 12.542 1.177 3.977 1.732 7.695.567 9.82-1.781 3.246-6.262 7.512-8.546 12.278z"
                            ></path>
                            <path
                                fill="#000"
                                d="M234.635 72.696c-1.781 3.247-6.262 7.513-8.546 12.279a115.943 115.943 0 00-1.325-4.374s-2.791-14.936 5.466-18.796a5.526 5.526 0 013.838 1.073c1.177 3.976 1.732 7.694.567 9.818z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M201.854 71.755l-7 2.042-9.72.376-1 8.447 15.974 1.15s3.99-11.217 1.746-12.015z"
                            ></path>
                            <path
                                fill="#FFB9B9"
                                d="M213.27 100.153s-9.366 1.554-7.228 4.372c2.138 2.819 10.434-.144 10.434-.144l-3.206-4.228z"
                            ></path>
                            <path
                                fill="#000"
                                d="M224.731 91.045c.212 4.09 1.718 8.819 2.259 12.258-2.984 2.824-5.82 5.314-6.89 5.356-2.21.086-5.76-.22-7.07.274-1.307.493-2.075-7.884-2.075-7.884s.781-2.685 4.264-4.148c2.351-.99 6.858-3.673 9.512-5.856z"
                                opacity="0.1"
                            ></path>
                            <path
                                fill="#131E46"
                                d="M230.127 59.154s7.477-1.175 7.073 11.23c-.404 12.406.788 20.324-2.219 22.653-3.007 2.33-12.775 12.885-14.984 12.971-2.209.086-5.76-.22-7.069.274-1.308.493-2.076-7.884-2.076-7.884s.781-2.685 4.264-4.148c3.484-1.462 11.69-6.648 11.621-8.415-.068-1.768-2.076-7.884-2.076-7.884s-2.791-14.937 5.466-18.797z"
                            ></path>
                            <circle
                                cx="211.735"
                                cy="41.283"
                                r="9.727"
                                fill="#FFB9B9"
                            ></circle>
                            <path
                                fill="#131E46"
                                d="M205.185 27.34a1.878 1.878 0 01-.818.411.584.584 0 01-.676-.468c-.223.461-.474.953-.928 1.19-.454.237-1.157.038-1.223-.47a2.145 2.145 0 01-.493 1.042c-.282.269-.793.337-1.04.035.2 1.087.077 2.206.14 3.31.062 1.103.356 2.273 1.19 2.999 1.216 1.058 3.045.797 4.645.597a1.41 1.41 0 01.728.04c.599.255.571 1.089.58 1.74.1 2.358 1.89 4.3 4.233 4.59a2.424 2.424 0 001.801-.439c.455-.385.687-1.012 1.207-1.301.847-.47 1.873.215 2.492.96.62.745 1.23 1.661 2.188 1.798 1.269.18 2.235-1.112 2.708-2.302a13.42 13.42 0 00-.266-10.428c-2.648-5.773-11.694-6.686-16.468-3.303z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IllustrationSecurity
