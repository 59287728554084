import React, { Fragment } from "react"
import SplitGrid from "../split-column-grid"
import { toHTML } from "./../../helpers/index"
interface IProps {
    edges: {
        node: {
            frontmatter: {
                paragraph_ab_two: string
            }
        }
    }
}
const About: React.FC<any> = ({ edges }: any) => {
    console.log(edges)
    const {
        paragraph_ab_two,
        paragraph_ab_three,
        heading_ab_two,
        picture_ab_two,
        picture_ab_three,
    } = edges.node.frontmatter
    return (
        <Fragment>
            <div id="omoss" />
            <SplitGrid
                text={toHTML(paragraph_ab_two)}
                subtitle={heading_ab_two}
                img={picture_ab_two?.childImageSharp?.gatsbyImageData}
                orientation={1}
            />
            <SplitGrid
                text={toHTML(paragraph_ab_three)}
                img={picture_ab_three?.childImageSharp?.gatsbyImageData}
                orientation={0}
            />
        </Fragment>
    )
}

export default About
