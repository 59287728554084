import styled from "styled-components"
import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import { deviceMax } from "./../styles/mediaqueries"
import { StyledImageText } from "../styles/global"
import { colors } from "./../styles/colors"
import { fontweight } from "./../styles/variables"

const StyledCard = styled.div<{ textOnly?: boolean | undefined }>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: ${(props) => (props.textOnly ? "360px" : "535px")};
    height: ${(props) => (props.textOnly ? "136px" : "222px")};
    margin: ${(props) => (props.textOnly ? "1.5rem 0.5rem" : "2.5rem 0")};
    max-width: 580px;
    background: white;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.lightSkyBlue};
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
        > .text-wrapper {
            > .svg-container {
                top: -31px;
            }
            > .image-text {
                > p.transition-fontweight {
                    font-weight: ${fontweight.semibold};
                }
            }
        }
    }

    @media ${deviceMax.laptop}, ${deviceMax.tablet} {
        max-width: 29rem;
        max-height: 17rem;
    }
    @media ${deviceMax.mobileL} {
        max-width: 22rem;
        max-height: 17rem;
    }
    @media ${deviceMax.mobileM} {
        max-width: 21rem;
        max-height: 18rem;
    }
    @media ${deviceMax.mobileS} {
        max-width: 18rem;
        max-height: 15rem;
    }
`

const StyledTextWrapper = styled.div`
    display: inline-grid;
    grid-template-rows: 1fr 0.7fr;
`

const StyledSvgContainer = styled.div`
    position: relative;
    top: -15px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all 0.2s ease;
    max-width: 20rem;
`

const StyledNoMarginWrapper = styled.div`
    margin: 0;
    -webkit-tap-highlight-color: transparent;
`

interface IProps {
    illustration?: any
    text?: string
    path?: any
    textOnly?: boolean | undefined // if true, the card will be displayed with only text
}
const Card: React.FC<IProps> = ({ illustration, text, path, textOnly }) => {
    const [hovered, setHovered] = useState<boolean>(true)

    const handleMouseEnter = () => {
        setHovered(true)
    }
    const handleMouseLeave = () => {
        setHovered(false)
    }
    return (
        <Fragment>
            <StyledNoMarginWrapper>
                <Link to={`${path}`}>
                    <StyledCard
                        textOnly={Boolean(textOnly)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <StyledTextWrapper className="text-wrapper">
                            {illustration && (
                                <StyledSvgContainer className="svg-container">
                                    {illustration}
                                </StyledSvgContainer>
                            )}
                            <StyledImageText
                                small={textOnly}
                                className="image-text"
                            >
                                <p
                                    className={`${
                                        hovered && "transition-fontweight"
                                    }`}
                                >
                                    {text}
                                </p>
                            </StyledImageText>
                        </StyledTextWrapper>
                    </StyledCard>
                </Link>
            </StyledNoMarginWrapper>
        </Fragment>
    )
}

export default Card
