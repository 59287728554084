import React from "react"
import { StyledSectionWrapper, StyledButton } from "../styles/global"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { deviceMax } from "../styles/mediaqueries"
import { fontweight } from "./../styles/variables"
import IllustrationSocial from "./Illustrations/IllustrationSocial"

const StyledHero = styled.div`
    display: grid;
    grid-template-columns: 627px 540px;
    justify-content: center;
    background-color: ${colors.blue};
    height: 100%;
    width: 100%;
    margin: 50px 0;

    @media ${deviceMax.laptopL} {
        grid-template-columns: 500px 518px;
    }
    @media ${deviceMax.laptop} {
        grid-template-columns: 396px 400px;
    }

    @media ${deviceMax.tablet},
        ${deviceMax.mobileL},
        ${deviceMax.mobileM},
        ${deviceMax.mobileS} {
        grid-auto-flow: column;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: unset;
        padding: 0 1rem;
    }
`

const StyledHeroTekst = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 0 3rem;
    & > h1 {
        font-size: 33px;
        margin-bottom: 2rem;
        font-weight: ${fontweight.semibold};
    }
    & > h2 {
        font-size: 24px;
        font-weight: ${fontweight.regular};
        @media ${deviceMax.mobileHorizontal} {
            font-size: 21px;
        }
    }
    @media ${deviceMax.tablet},
        ${deviceMax.mobileL},
        ${deviceMax.mobileM},
        ${deviceMax.mobileS} {
        padding: 2rem 1rem;
        flex-direction: column;
        max-width: 100%;
    }
`

const StyledHeroMedia = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    top: 8rem;
    @media ${deviceMax.laptop} {
        top: 7rem;
    }
    @media ${deviceMax.mobileM}, ${deviceMax.mobileL}, ${deviceMax.tablet} {
        top: 2rem;
        left: 1rem;
    }
    @media ${deviceMax.mobileS} {
        top: 3rem;
        left: 1rem;
    }
`

const StyledButtonWrapper = styled.div`
    z-index: 10;
`

const StyledExtraInfo = styled.div`
    position: absolute;
    bottom: 24px;
    right: 24px;
    background: white;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    border-radius: 16px;
    padding: 24px 32px;
    max-width: 300px;
    display: flex;
    flex-direction: column;

    & > p {
        margin: 12px 0;
    }
`

const StyledIllustrationWrapper = styled.div`
    position: relative;
    width: 30rem;
    top: -3rem;
    @media ${deviceMax.laptopNarrow} {
        width: 21rem;
    }
    @media ${deviceMax.mobileM} {
        left: -2rem;
        width: 21rem;
        top: 0;
    }
    @media ${deviceMax.mobileL} {
        left: -1rem;
        width: 20rem;
        top: 0;
    }
    @media ${deviceMax.mobileS} {
        left: -1rem;
        top: 1rem;
        width: 18rem;
    }
    @media ${deviceMax.tablet} {
        top: 1rem;
    }
`

const Hero: React.FC<{ heading: string; text: string }> = ({
    heading,
    text,
}) => {
    return (
        <div id="kontakt">
            <StyledSectionWrapper element>
                <StyledHero>
                    <StyledHeroTekst>
                        <h1>{heading}</h1>
                        <h2>{text}</h2>
                    </StyledHeroTekst>
                    <StyledHeroMedia>
                        <StyledButtonWrapper>
                            <StyledButton
                                onClick={() =>
                                    `${(location.href =
                                        "mailto:jobb@bblpivotal.no")}`
                                }
                            >
                                Send søknad
                            </StyledButton>
                        </StyledButtonWrapper>
                        <StyledIllustrationWrapper>
                            <IllustrationSocial height="100%" width="100%" />
                        </StyledIllustrationWrapper>
                    </StyledHeroMedia>
                    <StyledExtraInfo>
                        <strong>Mottatt penger fra BBL Datakompetanse AS?</strong>
                        <p>
                            Har du som privatperson mottatt penger av BBL
                            Datakompetanse AS? Dette er bonusutbetaling fra
                            fordelsprogrammet i ditt boligbyggelag som er
                            opptjent når du har gjort kjøp i butikker der du som
                            medlem i boligbyggelaget får medlemsfordeler
                        </p>
                        <a target="_blank" href="https://fordelerformedlemmer.no">
                            Gå til fordeler for medlemmer
                        </a>
                    </StyledExtraInfo>
                </StyledHero>
            </StyledSectionWrapper>
        </div>
    )
}

export default Hero
