import React from "react"
import styled from "styled-components"
import {
    ISplitGridProps,
    IStyledCardContentProps,
} from "../interface/style-types"
import {
    StyledSectionWrapper,
    StyledImage,
    StyledSectionContent,
    StyledHeadline,
} from "../styles/global"
import { deviceMax } from "../styles/mediaqueries"
import { IStyledSectionSplitContentProps } from "./../interface/style-types"
import { colors } from "./../styles/colors"
import { fontweight } from "./../styles/variables"

const StyledSectionSplitContent = styled.div<IStyledSectionSplitContentProps>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
    grid-gap: 10px;
    justify-content: center;
    & strong {
        font-size: 23px;
        margin: 0rem 3rem;
    }
    ${(props) =>
        props.reverseOnMin &&
        `
        @media ${deviceMax.laptop} {
        display: flex;
        flex-direction: column-reverse;
    }
    `}
    ${(props) =>
        props.flexColumn &&
        `
        display: flex;
        flex-direction: column;
        `}
`

const StyledCardContent = styled.div<IStyledCardContentProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80%;
    margin: 2.6rem;

    > h2 {
        font-size: 25px;
        color: ${colors.darkBlue};
    }
    > .html-content {
        > p {
            font-weight: ${fontweight.regular};
            font-size: 18px;
            color: ${colors.darkBlue};
            margin-top: 1em;
            line-height: 32px;
        }
    }
    ${(props) =>
        props.hasSubtitle &&
        `
            flex-direction: column;
            align-items: start;
        `}
    ${(props) =>
        props.orientationLeft &&
        `
            align-items: flex-start;
            text-align: left;
            
        `}
        ${(props) =>
        props.orientationRight &&
        `
            align-items: flex-end;
            text-align: right;
            @media ${deviceMax.mobileL} {
                align-items: flex-start;
                text-align: left;
            }
        `}

        @media ${deviceMax.laptop} {
        margin: 0rem 2.5rem;
    }
    @media ${deviceMax.mobileL} {
        margin: 0rem 2rem;
    }
    @media ${deviceMax.tablet} {
        margin: 0rem 2rem;
    }
    @media ${deviceMax.mobileM} {
        margin: 0 rem 2.3rem;
    }
`

const SplitGrid: React.FC<ISplitGridProps> = ({
    text,
    subtitle,
    img,
    orientation,
    flexColumn,
}) => {
    return (
        <StyledSectionWrapper>
            {orientation == 0 && (
                <StyledSectionSplitContent flexColumn={flexColumn}>
                    <StyledSectionContent text hasSubtitle>
                        <StyledCardContent orientationLeft>
                            {subtitle && (
                                <StyledHeadline>
                                    <h2 className="bbl-title">{subtitle}</h2>
                                </StyledHeadline>
                            )}
                            <div
                                className="html-content"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </StyledCardContent>
                    </StyledSectionContent>
                    <StyledSectionContent media="true">
                        {img && (
                            <StyledImage
                                image={img}
                                style={{
                                    minHeight: "calc(50vh - 15rem)",
                                    minWidth: "calc(50vh - 20rem)",
                                    maxHeight: "20rem",
                                    margin: "0 1rem",
                                }}
                                tag="div"
                                alt="Bilde"
                                className="fluid"
                            ></StyledImage>
                        )}
                    </StyledSectionContent>
                </StyledSectionSplitContent>
            )}

            {orientation == 1 && (
                <StyledSectionSplitContent>
                    <StyledSectionContent media="true">
                        {img && (
                            <StyledImage
                                image={img}
                                style={{
                                    minHeight: "calc(50vh - 15rem)",
                                    minWidth: "calc(50vh - 20rem)",
                                    maxHeight: "20rem",
                                    margin: "0 1rem",
                                }}
                                tag="div"
                                alt="Bilde"
                                className="fluid"
                            ></StyledImage>
                        )}
                    </StyledSectionContent>
                    <StyledSectionContent text hasSubtitle>
                        <StyledCardContent orientationRight>
                            <StyledHeadline>
                                <h2 className="bbl-title">{subtitle}</h2>
                            </StyledHeadline>
                            <div
                                className="html-content"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </StyledCardContent>
                    </StyledSectionContent>
                </StyledSectionSplitContent>
            )}
        </StyledSectionWrapper>
    )
}

export default SplitGrid
