import React from "react"
import Card from "./card"
import { StyledGrid } from "../styles/global"
import IllustrationDeveloper from "./Illustrations/IllustrationDeveloper"
import IllustrationDevProductivity from "./Illustrations/IllustrationDevProductivity"
import IllustrationSecurity from "./Illustrations/IllustrationSecurity"
import IllustrationGiftBox from "./Illustrations/IllustrationGiftBox"

const CardGrid: React.FC<any> = ({ data }: any) => {
    const {
        drifttitle,
        forretningsforseltitle,
        forsikringtitle,
        medlemsfordelertitle,
    } = data.node.frontmatter
    const getillustrations = (area: string) => {
        switch (area) {
            case "forretningsforsel":
                return (
                    <IllustrationDevProductivity
                        width={"80%"}
                        height={"100%"}
                    />
                )
            case "forsikringogskadevern":
                return <IllustrationSecurity width={"90%"} height={"100%"} />
            case "digitalarbeidsflate":
                return <IllustrationDeveloper width={"90%"} height={"100%"} />
            case "medlemsfordeler":
                return <IllustrationGiftBox width={"80%"} height={"100%"} />
        }
    }

    return (
        <StyledGrid>
            <Card
                illustration={getillustrations("forretningsforsel")}
                text={forretningsforseltitle}
                path="/forretningsforsel"
            />
            <Card
                illustration={getillustrations("forsikringogskadevern")}
                text={forsikringtitle}
                path={"/forsikringogskadevern"}
            />
            <Card
                illustration={getillustrations("digitalarbeidsflate")}
                text={drifttitle}
                path="/digitalarbeidsflate"
            />
            <Card
                illustration={getillustrations("medlemsfordeler")}
                text={medlemsfordelertitle}
                path="/medlemsfordeler"
            />
        </StyledGrid>
    )
}

export default CardGrid
